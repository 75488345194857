import React from 'react';
import { isValueCaptureToolEnabled } from '@/services/systemConfiguration.utilities';
import { Unauthorized } from '@/main/Unauthorized.page';
import { useTranslation } from 'react-i18next';

export const ValueCapture: React.FunctionComponent = () => {
  const { t } = useTranslation();
  if (!isValueCaptureToolEnabled()) return <Unauthorized />;

  return (
    <div className="flexColumnContainer flexFill flexCenter height-maximum">
      <div>{t('COMING_SOON')}</div>
    </div>
  );
};
