// @ts-strict-ignore
import _ from 'lodash';
import HttpCodes from 'http-status-codes';
import { ThresholdMetricInputV1 } from '@/sdk/model/ThresholdMetricInputV1';
import { sqMetricsApi } from '@/sdk/api/MetricsApi';
import { getTrendItemScopedTo } from '@/trend/trendDataHelper.utilities';
import { DISPLAY_MODE } from '@/main/app.constants';
import { PropertyOutputV1 } from 'sdk/model/PropertyOutputV1';
import { ScalarPropertyV1 } from 'sdk/model/ScalarPropertyV1';
import { sqFormulasApi } from '@/sdk/api/FormulasApi';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { errorToast } from '@/utilities/toast.utilities';
import { PUSH_IGNORE } from '@/core/flux.service';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { formatApiError } from '@/utilities/utilities';
import {
  createCalculatedItem,
  createCondition,
  createScalar,
  createSignal,
  FormulaCreator,
  updateFormulaItem,
} from '@/utilities/calculationRunner.utilities';
import { closeInvestigationTool, loadToolForEdit } from '@/toolSelection/investigate.actions';
import { setDisplayMode } from '@/toolSelection/investigateHelpers.actions';
import {
  addTrendItem,
  cancelPreviewCapsules,
  cancelPreviewSeries,
  catchItemDataFailure,
  fetchItemAndDependents,
  removePreviewCapsules,
  removePreviewSeries,
} from '@/trendData/trend.actions';
import { clearOutstandingDebouncedAsyncFunctions } from '@/utilities/asyncDebounce';
import { AnyProperty } from '@/utilities.types';
import { CONFIG_SCHEMA_VERSION } from '@/utilities/configUpgrader.utilities';
import { sqItemPropertiesStore, sqTrendMetricStore, sqWorkbenchStore } from '@/core/core.stores';
import { setDuplicatingItemId } from '@/tools/itemProperties/propertiesPanel.actions';
import { addMetricColumn, removeItemsFromTable } from '@/tableBuilder/tableBuilder.actions';
import { ProcessTypeEnum } from '@/sdk/model/ThresholdMetricOutputV1';
import { setHasCreatedCondition, setTriggerConfetti } from '@/workbench/workbench.actions';
import { isSystemTest } from '@/core/utilities';
import { broadcastSimpleMetricCreated } from '@/tools/thresholdMetric/thresholdMetric.actions';

export const panelExecuteCalculatedItem = panelExecuteFormula(createCalculatedItem);

export const panelExecuteCondition = panelExecuteFormula(createCondition());

export const panelExecuteSignal = panelExecuteFormula(createSignal());

export const panelExecuteScalar = panelExecuteFormula(createScalar());

/**
 }
 * Handles the logistics of what should happen when the "Execute" button is clicked on a tool panel that creates an
 * item. Store the UI Config, add the item to the trend, fetch dependencies, handle errors
 *
 * @param {Function} create - the create function is called to create a new item; should resolve with the item to be
 *   added
 * @param {Function} update - the update function is called to update an existing item
 * @param {Object} config - the UI Config to set on the item
 * @param {string} [id] - the unique id of an item if it already exists, or undefined if a new item
 * @param {string} [color] - the color the item should appear in the details pane
 * @param {boolean} [notifyOnError] - show a notification on error
 * @param {boolean} [closeOnSuccess] - close the investigate panel on success
 *
 * @returns { Promise<string> }
 */
export function panelExecute(
  create: () => Promise<any>,
  update: () => Promise<any>,
  config,
  id?: string,
  color?: string,
  { notifyOnError = true, closeOnSuccess = true } = {},
): Promise<any> {
  let promise: Promise<void>;
  let newItem: any;
  let props = {};
  const isNew = !id;

  if (config.type === TREND_TOOLS.FFT_TABLE || config.type === TREND_TOOLS.AGGREGATION_BINS_TABLE) {
    props = _.set(props, 'calculationType', config.type);
  }

  if (!isNew) {
    promise = update();
  } else {
    promise = create().then((item) => {
      id = item.id;
      newItem = item;
    });
  }

  return (
    promise
      // Set the UI Config as soon as we know the item exists, so that we reopen the correct tool panel on error
      .then(() =>
        sqItemsApi.setProperty({ value: JSON.stringify(config) }, { id, propertyName: SeeqNames.Properties.UIConfig }),
      )
      .then(() => {
        if (newItem && !_.isEmpty(sqItemPropertiesStore.duplicatingItemId)) {
          return duplicateProperties(sqItemPropertiesStore.duplicatingItemId, id);
        }
      })
      .finally(() => {
        setDuplicatingItemId('');
      })
      .then(() => {
        if (newItem) {
          if (color) {
            newItem.color = color;
          }

          return addTrendItem(newItem, props);
        } else {
          // Specifically not returning the promise so that user does not need to wait for all items to fetch before
          // closing
          fetchItemAndDependents(id);
        }
      })
      .then(() => {
        clearOutstandingDebouncedAsyncFunctions();
        cancelPreviewCapsules();
        removePreviewCapsules();
        cancelPreviewSeries();
        removePreviewSeries();
        if (closeOnSuccess) {
          closeInvestigationTool();
        }
        if (
          !isSystemTest() &&
          !sqWorkbenchStore.hasCreatedCondition &&
          newItem?.type === SeeqNames.Types.CalculatedCondition
        ) {
          setHasCreatedCondition(true);
          setTriggerConfetti(true, 'ACCOMPLISHMENTS.CONDITION_CREATED');
        }
        return Promise.resolve(id);
      })
      .catch((e) => {
        const status = _.get(e, 'status');

        // 504 is triggered when user cancels
        if (status !== HttpCodes.GATEWAY_TIMEOUT) {
          if (status === HttpCodes.BAD_REQUEST) {
            if (e.data.statusMessage) {
              e.data.statusMessage = e.data.statusMessage.replace(/[\s\S]+(Formula error .*)/, '$1');
            } else {
              e.data.statusMessage = 'Formula error: Unknown';
            }
          }

          if (notifyOnError) {
            errorToast({ httpResponseOrError: e });
          }

          catchItemDataFailure(id, undefined, e);
        }

        if (isNew && id) {
          loadToolForEdit(id);
        } else {
          setDisplayMode(DISPLAY_MODE.EDIT, PUSH_IGNORE);
        }

        return Promise.reject(formatApiError(e));
      })
  );
}

/**
 * Creates or updates a threshold metric item and updates the state of the tool panel.
 *
 * @param {String} type - the type that the function should result in
 * @param {Object} definition - An object defining a table
 * @param {String} definition.name - Name for display in the details panel.
 * @param {String} definition.formula - The formula to be run to generate the tables.
 * @param {String[]} definition.parameters - The bound parameters to run the formula (unbound parameters are added by
 *   this function)
 * @param {Object} [config] - the UI Config to set on the item
 * @param {string} [id] - the unique id of an item if it already exists, or undefined if a new item
 * @param {string} [color] - the color the item should appear in the details pane
 * @param {boolean} [notifyOnError] - show a notification on error
 * @param {boolean} [closeOnSuccess] - close the investigate panel on success
 *
 * @returns {Promise<string>}
 */
export function panelExecuteThresholdMetric(
  definition: ThresholdMetricInputV1,
  config,
  id?: string,
  color?: string,
  { notifyOnError = true, closeOnSuccess = true } = {},
) {
  _.assign(definition, { scopedTo: getTrendItemScopedTo(id) });
  return panelExecute(
    () =>
      sqMetricsApi.createThresholdMetric(definition).then(({ data }) => {
        if (data.processType === ProcessTypeEnum.Simple) {
          broadcastSimpleMetricCreated(data.id, definition);
        }

        return data;
      }),
    async () => {
      const oldProcessType = sqTrendMetricStore.findItem(id).definition.processType;
      const isCondition = (processType) =>
        [ProcessTypeEnum.Condition, ProcessTypeEnum.Continuous].includes(processType);

      const wasCondition = isCondition(oldProcessType);
      const { data } = await sqMetricsApi.putThresholdMetric(definition, { id });
      if (wasCondition && !isCondition(data.processType)) {
        removeItemsFromTable([data]);
      } else if (!wasCondition && isCondition(data.processType)) {
        addMetricColumn(data);
      }

      return data;
    },
    config,
    id,
    color,
    { notifyOnError, closeOnSuccess },
  );
}

/**
 * Creates or updates a formula item and updates the state of the tool panel.
 *
 * @param {String} type - the type that the function should result in
 * @param {Object} definition - An object defining a table
 * @param {String} definition.name - Name for display in the details panel.
 * @param {String} definition.formula - The formula to be run to generate the tables.
 * @param {String[]} definition.parameters - The bound parameters to run the formula (unbound parameters are added by
 *   this function)
 * @param {Object} config - the UI Config to set on the item
 * @param {string} [id] - the unique id of an item if it already exists, or undefined if a new item
 * @param {string} [color] - the color the item should appear in the details pane
 * @param {boolean} [notifyOnError] - show a notification on error
 * @param {boolean} [closeOnSuccess] - close the investigate panel on success
 *
 * @returns {Promise<string>}
 */
export function panelExecuteFormulaFunction(
  type,
  definition,
  config,
  id?: string,
  color?: string,
  { notifyOnError = true, closeOnSuccess = true } = {},
) {
  _.assign(definition, { scopedTo: getTrendItemScopedTo(id) });
  return panelExecute(
    () => sqFormulasApi.createFunction({ type, ...definition }).then(({ data }) => data),
    () => sqFormulasApi.updateFunction(definition, { id }).then(({ data }) => data),
    config,
    id,
    color,
    { notifyOnError, closeOnSuccess },
  );
}

/**
 * Creates a function that creates or updates a formula item and updates the state of the tool panel.
 *
 * @param creator - function from calculationRunner utilities that will create the item
 */
export function panelExecuteFormula(creator: FormulaCreator) {
  /**
   * Creates or updates a formula item and updates the state of the tool panel.
   *
   * @param name - Name for calculation
   * @param formula - The formula to pass to the Calculation Engine
   * @param parameters - Map of parameter name to ID that are the top-level parameters used in the formula
   * @param config - the UI Config to set on the item
   * @param [id] - the unique id of an item if it already exists, or undefined if a new item
   * @param [color] - the color the item should appear in the details pane
   * @param [notifyOnError] - show a notification on error
   * @param [closeOnSuccess] - close the investigate panel on success
   */
  return (
    name: string,
    formula: string,
    parameters,
    config: AnyProperty,
    id?: string,
    color?: string,
    { notifyOnError = true, closeOnSuccess = true } = {},
  ) =>
    panelExecute(
      () => creator({ name, scopedTo: getTrendItemScopedTo(id), formula, parameters }),
      () => updateFormulaItem({ id, name, formula, parameters }),
      { ...config, configVersion: CONFIG_SCHEMA_VERSION },
      id,
      color,
      { notifyOnError, closeOnSuccess },
    );
}

const SEEQ_PROPERTIES_TO_COPY: string[] = [SeeqNames.Properties.NumberFormat];
const SEEQ_PROPERTIES_TO_SKIP: string[] = _.filter(
  SeeqNames.Properties,
  (property) => !SEEQ_PROPERTIES_TO_COPY.includes(property),
);

async function duplicateProperties(sourceItemId: string, newItemId: string) {
  const properties = await sqItemsApi.getItemAndAllProperties({ id: sourceItemId });
  const propertiesToCopy: ScalarPropertyV1[] = properties.data.properties
    .filter((property) => !SEEQ_PROPERTIES_TO_SKIP.includes(property.name))
    .map((prop: PropertyOutputV1) => ({ name: prop.name, value: prop.value, unitOfMeasure: prop.unitOfMeasure }))
    .concat(
      properties.data.description
        ? { name: SeeqNames.Properties.Description, value: properties.data.description, unitOfMeasure: undefined }
        : [],
    );
  if (propertiesToCopy.length > 0) {
    await sqItemsApi.setProperties(propertiesToCopy, { id: newItemId });
  }
}
