import _ from 'lodash';
import React, { useState } from 'react';
import { TooltipPosition } from '@seeqdev/qomponents/dist/Tooltip/Tooltip.types';
import { Icon } from '@seeqdev/qomponents';
import { IconType } from '@seeqdev/qomponents/dist/Icon/Icon.types';
import { TableFilterPopover } from '@/core/tableUtilities/TableFilterPopover.organism';
import { TableColumnFilter } from '@/core/tableUtilities/tables';
import { ThresholdOutputV1 } from '@/sdk/model/ThresholdOutputV1';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';

export interface FilterDropdownProps {
  columnKey: string;
  setColumnFilter?: (key: string, filter: TableColumnFilter) => void;
  iconType?: IconType;
  columnFilter?: TableColumnFilter;
  isFilterDisabled?: boolean;
  isStringColumn?: boolean;
  isDurationColumn?: boolean;
  distinctStringValues?: string[];
  helpText?: string;
  thresholds?: ThresholdOutputV1[];
  placement?: TooltipPosition;
  onIconClick?: () => void;
  isInteractiveContent?: boolean;
  isWorkbookLocked?: boolean;
}

export const TableFilterIcon: React.FunctionComponent<FilterDropdownProps> = ({
  columnKey,
  setColumnFilter,
  iconType = 'theme',
  columnFilter,
  isFilterDisabled = false,
  isStringColumn = false,
  isDurationColumn = false,
  distinctStringValues,
  helpText,
  thresholds,
  placement = 'bottom',
  onIconClick = _.noop,
  isInteractiveContent,
  isWorkbookLocked,
}) => {
  const isFiltered = !_.isUndefined(columnFilter);
  const showFilterIcon = isFiltered || isInteractiveContent;

  const [showColumnFilterPopover, setShowColumnFilterPopover] = useState(false);

  const filterIconType: IconType = isInteractiveContent && !columnFilter ? 'gray' : iconType ?? 'theme';

  return (
    <>
      {!isFilterDisabled && showFilterIcon && !headlessRenderMode() && (
        <TableFilterPopover
          columnFilter={columnFilter}
          columnKey={columnKey}
          notifyOnClose={() => setShowColumnFilterPopover(false)}
          setColumnFilter={(key, filter) => setColumnFilter?.(key, filter!)}
          isStringColumn={isStringColumn}
          isDurationColumn={isDurationColumn}
          distinctStringValues={distinctStringValues}
          thresholds={thresholds}
          helpText={helpText}
          isWorkbookLocked={isWorkbookLocked}
          placement={placement}
          trigger={
            <div
              className="sq-icon-hover cursorPointer"
              data-testid="columnFilterIconWrapper"
              id={`filterDropdown-${columnKey}`}>
              <Icon
                icon="fa-filter"
                extraClassNames="fa-fw mt2"
                testId="columnFilterIcon"
                onClick={(e) => {
                  onIconClick();
                  setShowColumnFilterPopover(true);
                }}
                type={filterIconType}
              />
            </div>
          }
          show={!isFilterDisabled && showColumnFilterPopover}
          isInteractiveContent={isInteractiveContent}
        />
      )}
      {isFilterDisabled && isFiltered && (
        <Icon icon="fa-filter" extraClassNames="fa-fw mt2 ml5" testId="columnFilterIcon" type={filterIconType} />
      )}
    </>
  );
};
