// @ts-strict-ignore
import React from 'react';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { Field, useForm } from 'react-final-form';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { ButtonGroup, ButtonItem } from '@/core/ButtonGroup.molecule';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';

export interface ButtonGroupIF extends ValidatingFormComponent<string> {
  component: 'ButtonGroupFormComponent';
  buttonItems: ButtonItem[];
}

/**
 * A wrapper around the `ButtonGroup` component for use with `FormBuilder`
 */
export const ButtonGroupFormComponent: React.FunctionComponent<ButtonGroupIF> = (props) => {
  const {
    name,
    testId = 'buttonGroup',
    label,
    buttonItems,
    validation,
    extendValidation,
    wrapperClassNames,
    extraClassNames,
    required,
    tooltip,
    tooltipPlacement = 'top',
  } = props;

  const defaultValidation = (value) => required && !value;

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);
  const formState = useForm().getState();

  return (
    <FormFieldWrapper
      testId={testId}
      label={label}
      tooltip={tooltip}
      tooltipPlacement={tooltipPlacement}
      wrapperClassNames={wrapperClassNames}
      extraClassNames={extraClassNames}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const { value, name, onChange } = getFormFieldProps(formState, input, meta, props);

          return (
            <ButtonGroup
              buttonItems={buttonItems}
              name={name}
              value={value}
              onChange={onChange}
              testId={`${testId}Field`}
            />
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};
