// @ts-strict-ignore
import moment from 'moment-timezone';
import _ from 'lodash';
import React from 'react';
import { createRoot, Root as IReactRoot } from 'react-dom/client';
import { Root } from '@/core/Root';
import {
  BasePluginDependencies,
  CONTENT_MODEL_ATTRIBUTES,
  ContentDisplayMode,
  ImageContentListenerCommand,
} from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { createHtmlPortalNode, HtmlPortalNode, InPortal, OutPortal } from 'react-reverse-portal';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';
import { AssetSelection, Content, DateRange } from '@/reportEditor/report.constants';
import type { Editor } from '@ckeditor/ckeditor5-core';
import type { ViewDocumentFragment, ViewElement, ViewNode } from '@ckeditor/ckeditor5-engine';

const CK_RESET_CLASS = 'ck-reset_all';

export function renderElementWithRoot(
  pluginDependencies: BasePluginDependencies,
  domElement,
  componentToRender: JSX.Element,
  existingRoot?: IReactRoot,
): IReactRoot {
  const root = existingRoot ?? createRoot(domElement);
  root.render(<Root {...pluginDependencies}>{componentToRender}</Root>);
  return root;
}

/**
 * Renders the given react component inside an InPortal, passing the portalNode the rendered element, and then
 * rendering to the node to an OutPortal at [domElement].
 *
 * @param pluginDependencies - Editor plugin dependencies for the Root that the element is rendered in
 * @param domElement - The dom element the OutPortal will be rendered to
 * @param reactComponent - The actual component being rendered
 * @param props - The props for said component
 * @returns - The floating element the InPortal is created on, allowing for cleanup of the InPortal later on
 */
export function renderElementWithPortal(
  pluginDependencies: BasePluginDependencies,
  domElement,
  reactComponent: React.FunctionComponent<any>,
  props: any,
): IReactRoot {
  const portalNode = createHtmlPortalNode();
  portalNode.element.classList.add('inheritMinHeightAndHeight');
  const portalDomElement = document.createElement('div');

  const root = createRoot(portalDomElement);
  root.render(
    <Root {...pluginDependencies}>
      <InPortal node={portalNode}>
        <Root {...pluginDependencies}>{React.createElement(reactComponent, { ...props, portalNode }, null)}</Root>
      </InPortal>
    </Root>,
  );
  root.render(
    <InPortal node={portalNode}>
      <Root {...pluginDependencies}>{React.createElement(reactComponent, { ...props, portalNode }, null)}</Root>
    </InPortal>,
  );
  const rootPortal = createRoot(domElement);
  rootPortal.render(<OutPortal node={portalNode} />);

  return root;
}

export function moveNode(domElement, portalNode: HtmlPortalNode) {
  const root = createRoot(domElement);
  root.render(<OutPortal node={portalNode} />);
}

// Some CK image functions needs an imageUtils helper that is annoyingly difficult to grab. We only need one
// function from the helper, so this is it. Confusingly, they use this function for both inline and block, so it
// seems to just be poorly named.
export const replacementImageUtils = {
  isInlineImageView: (node: any) => node && node.is('element', 'img'),
};

export function isNonContentImage(node: any) {
  return (
    node.is('element', 'img') &&
    node.getAttribute('src') &&
    !node.getAttribute(CONTENT_MODEL_ATTRIBUTES.DATA_SEEQ_CONTENT)
  );
}

export function forceReloadContent(contentId: string) {
  const contentImg = getContent(contentId);
  if (contentImg) {
    const event = new Event('load');
    contentImg.dispatchEvent(event);
  }
}

/**
 * CK adds the ck-reset_all class to its toolbar which adds a bunch of styles that are hard to override in the
 * dropdowns. When toggling it off, make to sure to re-toggle so that other CK styles work as expected.
 */
export function toggleCkResetClass() {
  const toolbarContainer = document.getElementById('journalEditorToolbarContainer');
  const toolbar = toolbarContainer.querySelector('.ck-toolbar');
  toolbar.classList.contains(CK_RESET_CLASS)
    ? toolbar.classList.remove(CK_RESET_CLASS)
    : toolbar.classList.add(CK_RESET_CLASS);
}

export function buildRelativeContentUrl(contentId: string): string {
  return `${APPSERVER_API_PREFIX}/content/${contentId}/sourceUrl`;
}

export function buildAbsoluteContentUrl(
  baseUrl: string,
  content: Content,
  maybeDateRange?: DateRange,
  maybeAssetSelection?: AssetSelection,
): string {
  const url = new URL(`${baseUrl}/view/worksheet/${content.workbookId}/${content.worksheetId}`);
  url.searchParams.append('workstepId', content.workstepId);
  if (maybeDateRange) {
    url.searchParams.append('displayRangeStart', moment.utc(maybeDateRange.range.start).toISOString());
    url.searchParams.append('displayRangeEnd', moment.utc(maybeDateRange.range.end).toISOString());
  }
  if (content.timezone) {
    url.searchParams.append('timezone', content.timezone);
  }
  if (maybeAssetSelection) {
    url.searchParams.append('assetId', maybeAssetSelection.asset.id);
  }
  return url.toString();
}

export function getContentSpecificCommand(command: ImageContentListenerCommand, contentId: string) {
  return `${command}-${contentId}`;
}

/**
 * Uses javascript to find out if content is in the document, because it is possible that contentMetadata is out of
 * sync, and using that metadata to determine if content is in the document can fail. We know that this will give
 * us the most up to date information because it checks what is actually visible to the user.
 *
 * @param contentId for the piece of content we are checking
 */
export function isContentInDocument(contentId: string): boolean {
  return !_.isNil(getContent(contentId));
}

export function getContent(contentId: string): Element | null {
  return document.querySelector(`[${CONTENT_MODEL_ATTRIBUTES.DATA_SEEQ_CONTENT}="${contentId}"]`);
}

export function getContentWidgetViewElement(contentImage: Node, editor: Editor): ViewElement {
  const imageView: ViewDocumentFragment | ViewNode = editor.editing.view.domConverter.domToView(contentImage);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return imageView.findAncestor({ name: 'div', classes: 'seeqContentWrapper' });
}

export function getContentDisplayMode(canModify: boolean, isPDF: boolean): ContentDisplayMode {
  if (isPDF) {
    return ContentDisplayMode.PDF;
  } else if (!canModify) {
    return ContentDisplayMode.VIEW_ONLY;
  } else {
    return ContentDisplayMode.NORMAL;
  }
}
