import * as React from 'react';
import { Icon } from '@seeqdev/qomponents';
import { setAIAssistantDisplayed } from '@/aiAssistant/aiAssistant.actions';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { useTranslation } from 'react-i18next';
import { sqAiAssistantStore } from '@/core/core.stores';

export const HeaderAIAssistant: React.FunctionComponent = () => {
  const aiAssistantDisplayed = useFluxPath(sqAiAssistantStore, () => sqAiAssistantStore.displayed);
  const { t } = useTranslation();

  return (
    <>
      <Icon
        icon="fa-sparkles"
        iconPrefix="fa-solid"
        testId="headerAIIcon"
        type="white"
        extraClassNames="pl5 pt3 pr5 sq-navbar-default fa-xl"
        tooltip={t('AI_ASSISTANT.AI_ASSISTANT')}
        tooltipPlacement="bottom"
        onClick={() => {
          setAIAssistantDisplayed(!aiAssistantDisplayed);
        }}
      />
    </>
  );
};
