// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import {
  getFormFieldProps,
  getFormFieldWrapperProps,
  getValidationFunction,
} from '@/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import { TextField, TextArea } from '@seeqdev/qomponents';
import { InputLengthStyleProps, TextFieldProps } from '@seeqdev/qomponents/dist/TextField/TextField.types';
import { TextAreaProps } from '@seeqdev/qomponents/dist/TextArea/TextArea.types';

export interface TextFieldIF extends ValidatingFormComponent<string | number> {
  component: 'TextFieldFormComponent';
  id?: string;
  size: 'sm' | 'lg';
  placeholder?: string;
  autoComplete?: string;
  type?: 'number' | 'text' | 'email' | 'password';
  step?: number;
  maxLength?: number;
  minLength?: number;
  max?: number;
  min?: number;
  rows?: number;
  as?: string;
  onBlur?: (value: string) => void;
  onFocus?: (value: string) => void;
  onKeyDown?: (key: string, value: string) => void;
  disabled?: boolean;
  skipMemo?: boolean;
  autoFocus?: boolean;
  displayError?: boolean;
  customErrorText?: string;
  labelIcon?: string;
  labelIconAction?: () => void;
  labelIconTooltip?: string;
  labelClassNames?: string;
}

export const TextFieldFormComponent: React.FunctionComponent<TextFieldIF> = (props) => {
  const {
    name,
    extendValidation,
    validation,
    placeholder,
    onChange,
    onBlur,
    onKeyDown,
    type = 'text',
    step,
    testId = 'formControl',
    required,
    minLength,
    maxLength,
    min,
    max,
    customErrorText,
    labelIcon,
    labelIconAction,
    labelIconTooltip,
    labelClassNames,
  } = props;

  const { t } = useTranslation();

  const defaultValidation = (value: string) =>
    (required && _.isEmpty(_.trim(value))) ||
    (minLength && value?.length < minLength) ||
    (maxLength && value?.length > maxLength) ||
    (min && (isNaN(Number(value)) || Number(value) < min)) ||
    (max && (isNaN(Number(value)) || Number(value) > max));

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);
  const formState = useForm().getState();
  const showError =
    _.has(formState.errors, name) &&
    formState.hasValidationErrors &&
    (formState.modified[name] || formState.submitFailed);

  return (
    <FormFieldWrapper
      {...getFormFieldWrapperProps(props, ['id'])}
      testId={`${testId}-wrap`}
      labelIcon={labelIcon}
      labelIconAction={labelIconAction}
      labelIconTooltip={labelIconTooltip}
      labelClassNames={labelClassNames}
      showError={showError}
      customErrorText={customErrorText}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const properProps = _.assign({}, getFormFieldProps(formState, input, meta, props), {
            placeholder: t(placeholder),
            value: input.value,
            onChange: (e) => {
              input.onChange(e.target.value);
              onChange?.(e.target.value);
            },
            onBlur: (e) => _.isFunction(onBlur) && onBlur(e.target.value),
            onKeyDown: (e) => onKeyDown?.(e.key, e.target.value),
            onFocus: (e) => e.target.select(),
          });

          return props.as === 'textarea' && (props.rows || 0) > 1 ? (
            <TextAreaMemo
              id={properProps.id}
              name={properProps.name}
              onChange={properProps.onChange}
              onKeyUp={properProps.onKeyUp}
              onKeyDown={properProps.onKeyDown}
              onFocus={properProps.onFocus}
              onBlur={properProps.onBlur}
              placeholder={properProps.placeholder}
              value={properProps.value}
              cols={properProps.cols}
              showError={properProps.showError}
              readonly={properProps.readonly}
              disabled={properProps.disabled}
              extraClassNames={`width-maximum ${properProps.extraClassNames}`}
              testId={testId}
              rows={properProps.rows}
              autoFocus={properProps.autoFocus}
            />
          ) : (
            <TextFieldMemo
              id={properProps.id}
              name={properProps.name}
              onChange={properProps.onChange}
              onKeyUp={properProps.onKeyUp}
              onKeyDown={properProps.onKeyDown}
              onFocus={properProps.onFocus}
              onBlur={properProps.onBlur}
              placeholder={properProps.placeholder}
              size={props.size}
              value={properProps.value}
              type={type}
              autoComplete={properProps.autoComplete}
              maxLength={properProps.maxLength}
              minLength={properProps.minLength}
              min={properProps.min}
              max={properProps.max}
              ref={properProps.ref}
              inputGroup={properProps.inputGroup}
              showError={properProps.showError}
              readonly={properProps.readonly}
              disabled={properProps.disabled}
              extraClassNames={`width-maximum ${properProps.extraClassNames}`}
              testId={testId}
              step={step}
              autoFocus={properProps.autoFocus}
              required={properProps.showError && properProps.required}
            />
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};

export const TextFieldMemo = React.memo<TextFieldProps & InputLengthStyleProps & { skipMemo?: boolean }>(
  TextField,
  (prev, next) =>
    !(
      prev.onChange !== next.onChange ||
      !_.isEqual(prev.value, next.value) ||
      prev.readonly !== next.readonly ||
      prev.placeholder !== next.placeholder ||
      prev.extraClassNames !== next.extraClassNames ||
      prev.skipMemo
    ),
);

const TextAreaMemo = React.memo<TextAreaProps & { skipMemo?: boolean }>(
  TextArea,
  (prev, next) =>
    !(
      prev.onChange !== next.onChange ||
      !_.isEqual(prev.value, next.value) ||
      prev.readonly !== next.readonly ||
      prev.placeholder !== next.placeholder ||
      prev.extraClassNames !== next.extraClassNames ||
      prev.skipMemo
    ),
);
