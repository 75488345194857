// @ts-strict-ignore
import HttpCodes from 'http-status-codes';
import _ from 'lodash';
import { getAxiosInstance } from '@/requests/axios.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { generateRequestId } from '@/utilities/http.utilities';
import { removeRequest } from '../axios.actions';

export function conflictRetryInterceptor(error) {
  const MAXIMUM_RETRIES = 5;
  if (error) {
    if (_.get(error, 'response.status') !== HttpCodes.CONFLICT) {
      return Promise.reject(error);
    }

    error.response.config.retry = error.response.config.retry || 0;

    if (error.response.config.retry < MAXIMUM_RETRIES) {
      // Before we retry, we need to remove the previous request from the pending requests list.
      // This is necessary because we don't issue a rejection, which means that the downstream
      // interceptor where we normally remove requests (decrementPendingRequestsError) won't be called.
      removeRequest(error.response.config?.headers[SeeqNames.API.Headers.RequestId]);

      error.response.config.retry++;
      return Promise.resolve().then(() =>
        getAxiosInstance().request({
          ...error.response.config,
          headers: {
            ...error.response.config.headers,
            [SeeqNames.API.Headers.RequestId]: generateRequestId(),
          },
        }),
      );
    } else {
      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
}
