// @ts-strict-ignore
import { SeeqNames } from '@/main/app.constants.seeqnames';
import _ from 'lodash';
import { generateRequestId, handleForbidden, isCanceled } from '@/utilities/http.utilities';
import HttpCodes from 'http-status-codes';
import {
  asyncRequestAccepted,
  AsyncResponseMessage,
  cancelWait,
  waitForAsyncResponse,
} from '@/core/asyncResponses.utilities';
import { logWarn } from '@/utilities/logger';
import { formatMessage } from '@/utilities/logger.utilities';
import { t } from 'i18next';
import { isAsyncEnabled } from '@/services/systemConfiguration.utilities';

/**
 * HTTP interceptor that handles requests that may send responses asynchronously. We specify a header that allows
 * all requests to be sent asynchronously. The backend has a "allow list" of URLs that are allowed to be sent back
 * asynchronously. When responses are returned, this interceptor automatically handles HTTP responses
 * of status 202, leaving the response promises unsatisfied until the async responses are received via the websocket
 * connection. If the response is sent synchronously, all async handlers are cleaned up automatically.
 *
 * @returns {Promise} that resolves when the response is available or determined to be missing/orphaned. Returns a
 *   rejected promise if HTTP status code indicates a failure.
 */
export function asyncHttpRequestInterceptor(config) {
  const asyncDisabled =
    _.includes(window.location.search, 'async-disabled') ||
    config.asyncDisabled ||
    (!isAsyncEnabled() && !config.useManualAsync);
  if (!asyncDisabled) {
    // Allow all requests to be async; the backend will determine whether to return sync or async
    config.headers[SeeqNames.API.Headers.Async] = true;
    let requestId = config.headers[SeeqNames.API.Headers.RequestId];
    // make sure a request ID is specified; otherwise generate one, since we need it to wait for possible async
    // response
    if (!requestId) {
      requestId = generateRequestId();
      config.headers[SeeqNames.API.Headers.RequestId] = requestId;
    }

    // Setup the async wait now so that there's no possibility of a race condition where the response arrives before
    // the wait is registered
    config.asyncPromise = waitForAsyncResponse(requestId);
  }

  return config;
}

export function asyncHttpResponseInterceptor(response) {
  if (!response) {
    return response;
  }
  const requestId = response.config.headers[SeeqNames.API.Headers.RequestId];

  // ACCEPTED (202) == http async
  if (response.status === HttpCodes.ACCEPTED && !response.config.useManualAsync) {
    asyncRequestAccepted(requestId);

    return response.config.asyncPromise.then((asyncData: AsyncResponseMessage) => {
      // Roughly match the legacy 'headerGetter' behavior with case insensitivity
      const mergedHeaders = _.mapKeys(_.assign({}, response.headers, asyncData.headers), (value, key: string) =>
        _.toLower(key),
      );
      response.headers = mergedHeaders;
      response.status = asyncData.status;
      response.statusText = asyncData.statusText;
      response.data = asyncData.body;

      if (response.status >= 400 || response.status < 0) {
        // With async requests it is much harder to see failing requests in devtools -- especially if we
        // silently ignore the error downstream
        if (!isCanceled(response)) {
          handleForbidden(response);
          logWarn(formatMessage`Async Response: ${response}`);
        }

        return Promise.reject(response);
      } else {
        return Promise.resolve(response);
      }
    });
  } else if (requestId && response.config.asyncPromise) {
    // Response not async; cleanup async handlers and return the sync response directly
    cancelWait(requestId);
  }

  return response;
}

export function asyncResponseErrorInterceptor(error) {
  if (error) {
    if (error.isAxiosError && _.isUndefined(error.response)) {
      return Promise.reject({
        ...error,
        response: { data: { statusMessage: t('NO_SERVER_CONNECTION') }, status: HttpCodes.INTERNAL_SERVER_ERROR },
      });
    }

    const requestId = error.response?.config?.headers[SeeqNames.API.Headers.RequestId];
    if (requestId && error.response?.config?.asyncPromise) {
      cancelWait(requestId);
    }

    return Promise.reject(error);
  }

  return error;
}
