import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@seeqdev/qomponents';
import { LockFailureReasons } from '@/accessControl/LockFailureReasons.atom';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { sqWorkbookStore } from '@/core/core.stores';
import { LockErrorOutputV1 } from '@/sdk';
import { LockedMetadataIF } from '@/accessControl/AclModalLockTab.molecule';

/**
  Display a list of global items that are preventing a user locking an Analysis. Underneath each item, display a
  reason why the lock failed and a link to where the item is used in the user's Analysis.
 */

interface GlobalItemsProps {
  hasLockingBeenAttempted: boolean;
}

export const GlobalItems: React.FunctionComponent<GlobalItemsProps> = ({ hasLockingBeenAttempted }) => {
  const { t } = useTranslation();
  const { lockingErrors } = useFlux(sqWorkbookStore);

  const HelpIcon = (
    <a href="https://support.seeq.com/kb/latest/cloud/locking-a-workbench-analysis" target="_blank" rel="noopener">
      <Icon
        icon="fa-question-circle"
        large={true}
        tooltip={t('ACCESS_CONTROL.LOCKING.GLOBAL_ITEMS_TOOLTIP')}
        extraClassNames="text-interactive"
      />
    </a>
  );

  return (
    <>
      {lockingErrors && lockingErrors.length > 0 && (
        <>
          <div className="pb10" data-testid="cannotLockTitle">
            {hasLockingBeenAttempted && (
              <>
                <strong>{t('ACCESS_CONTROL.LOCKING.CANNOT_LOCK')}</strong> {HelpIcon}
              </>
            )}
            {!hasLockingBeenAttempted && (
              <>
                <strong>{t('ACCESS_CONTROL.LOCKING.PREVIOUSLY_ATTEMPT_LOCKED')}</strong> {HelpIcon}
                <div>
                  <strong>{t('ACCESS_CONTROL.LOCKING.REATTEMPT_LOCKING')}</strong>
                </div>
              </>
            )}
          </div>
          <div className="bg-gray p10">
            {lockingErrors.length > 0 &&
              lockingErrors.map((globalItem: LockErrorOutputV1) => (
                <div key={`error-container-${globalItem.item.id}`} className="mb20">
                  <LockFailureReasons itemName={globalItem.item.name} globalItem={globalItem} />
                </div>
              ))}
          </div>
        </>
      )}
    </>
  );
};
