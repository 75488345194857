import { SeeqNames } from '@/main/app.constants.seeqnames';
import {
  ItemTypeOptions,
  PropertyMatchOperatorEnumToLabel,
  PropertyNameOptions,
  CombinedColumnRuleInputParameters,
} from '@/tableDefinitionEditor/columnRules/columnRule.constants';
import {
  ColumnDefinitionOutputV1,
  ColumnRuleAncestorInputV1,
  ColumnRuleAssetCreatorInputV1,
  ColumnRuleConcatInputV1,
  ColumnRuleConstantInputV1,
  ColumnRuleDescendantInputV1,
  ColumnRuleEventPropertyInputV1,
  ColumnRuleFormulaCreatorInputV1,
  ColumnRuleGetItemPropertyInputV1,
  ColumnRuleItemSearchInputV1,
  ColumnRuleOutputV1,
  ColumnRulePathInputV1,
  ColumnRulePathSearchInputV1,
  ColumnRuleScalarCreatorInputV1,
  ColumnRuleSetItemPropertyInputV1,
  ColumnRuleTextExtractorInputV1,
  ColumnRuleTextReplacementInputV1,
  ColumnRuleTreePathCreatorInputV1,
} from '@/sdk';
import { ColumnTypeEnum } from '@/sdk/model/ColumnDefinitionInputV1';
import { PropertyMatchOperatorEnum } from '@/sdk/model/ColumnRuleDescendantInputV1';
import {
  ColumnInputOptions,
  ColumnRuleWithMetadata,
  DropdownInputOptions,
  NumberInputOptions,
  ParameterWithMetadata,
  TextInputOptions,
} from '@/tableDefinitionEditor/columnRules/columnRuleBuilder.types';
import { TableDefinitionAccessSettings } from '@/tableDefinitionEditor/tableDefinition.types';
import { t } from 'i18next';
import _ from 'lodash';

export const RULES_WITH_PERMISSIONS: string[] = [
  SeeqNames.MaterializedTables.Rules.FormulaCreator,
  SeeqNames.MaterializedTables.Rules.AssetCreator,
  SeeqNames.MaterializedTables.Rules.ScalarCreator.BaseScalarCreator,
];

export const CREATE_TREE_PSUEDO_RULE = 'AssetTreeCreator';
export const ADD_TO_TREE_PSUEDO_RULE = 'AssetTreeAddChild';
export const DEFAULT_ITEM_ID_COLUMN_NAME = 'Search Items';

const TreePathCreatorAllParameters: ParameterWithMetadata[] = [
  buildColumnDropdownParameterWithMetadata({
    parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndexes,
    label: 'SCALING.PROPERTIES.COLUMN_INPUT',
    allowedColumnInputTypes: [ColumnTypeEnum.UUID],
    isMultiple: true,
    required: true,
    isSearchable: true,
    showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
      return {
        isError: !ruleState.columnIndexes || ruleState.columnIndexes.length < 2,
      };
    },
    getDefaultValue: (allColumnDefinitions) => {
      return [allColumnDefinitions!.findIndex((col) => col.displayName === DEFAULT_ITEM_ID_COLUMN_NAME) + 1];
    },
  }),
  buildTextParameterWithMetadata({
    parameter: SeeqNames.MaterializedTables.Parameters.Separator,
    label: 'SCALING.PROPERTIES.SEPARATOR',
    placeholder: 'SCALING.PARAMETER_OPTIONAL_INPUT_PLACEHOLDER',
    required: false,
    showError: () => {
      return {
        isError: false,
      };
    },
  }),
];

/**
 * This is an ordered list of all the column rules that are available to used in the UI. The order of the rules in
 * this list are the order they will appear in the UI.
 *
 * In order for a rule to be used in the UI, it must be added to this list. A complete rule configuration will require
 * the rule name and some display related metadata bout the rule, as well as a complete {@link ParameterWithMetadata}
 * for each potential parameter that the rule can take. A parameter configuration includes the parameter name and
 * display related metadata, as well as the input type for the parameter. The input type is used to determine what kind
 * of input field will be rendered in the UI for the parameter.
 *
 * The last thing each configuration will require is a function that will take a {@link ColumnRuleOutputV1} and an
 * array
 * of {@link ColumnDefinitionOutputV1} and return the input object for the rule. This function will be used to convert
 * the output of a rule to the input of a rule when editing a rule.
 *
 * The Formula Rule is a good example of a custom rule that uses its own UI component to configure the rule, rather
 * than the more general component that we use to render other rule inputs. The Formula rule and the next couple
 * rules will give added explanations for how to configure a rule.
 */
export const ColumnRulesWithMetaData: ColumnRuleWithMetadata[] = [
  // --- Formula Creator ---
  {
    rule: SeeqNames.MaterializedTables.Rules.FormulaCreator,
    label: 'SCALING.RULES.FORMULA_CREATOR.NAME',
    description: 'SCALING.RULES.FORMULA_CREATOR.DESCRIPTION',
    // Note that this rule is only allowed on UUID columns
    allowedOnColumnTypes: [ColumnTypeEnum.UUID],
    /** Note that these parameters are configured using some helper functions, but a parameter can also be
     *  configured declaratively, just look at the type {@link ParameterWithMetadata} and its documentation and add
     *  objects of that type to the {@link allParameters} field */
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndexes,
        label: 'SCALING.PROPERTIES.COLUMN_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.UUID],
        isMultiple: true,
        required: true,
        isSearchable: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.columnIndexes,
          };
        },
      }),
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndexDataId,
        label: 'SCALING.PROPERTIES.COLUMN_DATA_ID_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.TEXT],
        required: false,
        isSearchable: true,
        showError: () => {
          return {
            isError: false,
          };
        },
      }),
      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Formula,
        label: 'SCALING.PROPERTIES.FORMULA',
        placeholder: 'SCALING.PROPERTIES.FORMULA',
        required: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.formula,
          };
        },
      }),
      buildCustomParameterWithMetadata({
        // this is actually a list in the API
        parameter: SeeqNames.MaterializedTables.Parameters.ParametersName,
        label: 'SCALING.PROPERTIES.PARAMETERS',
        required: true,
        showError: () => {
          return {
            isError: false,
          };
        },
      }),
      // The name parameter is a standard text input field, which means the custom Formula Creator component can
      // reuse the same text input component that is used for other text parameters
      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Name,
        label: 'SCALING.PROPERTIES.NAME',
        placeholder: 'SCALING.PARAMETER_INPUT_PLACEHOLDER',
        required: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.name,
          };
        },
      }),
      // The description parameter is a standard text input field, like Name, but note that it is allowed to be
      // resizable and that this parameter is NOT required. The required field should indicate whether the api
      // expects this field to be required or not.
      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Description,
        label: 'SCALING.PROPERTIES.DESCRIPTION',
        placeholder: 'SCALING.PARAMETER_OPTIONAL_INPUT_PLACEHOLDER',
        isResizable: true,
        required: false,
        showError: () => {
          return {
            isError: false,
          };
        },
      }),
      // The following parameters are currently not exposed to the user, and so they are built as custom components
      // right now. If we decide to expose them to the user, we can change the input type to a standard input type
      // or to match a new input type that we create for them.
      buildDefaultScopedToParameterWithMetadata(),
      buildDefaultIdentityIdParameterWithMetadata(),
      buildDefaultPermissionStringParameterWithMetadata(),
      buildCustomParameterWithMetadata({
        // this is actually a list in the API
        parameter: SeeqNames.MaterializedTables.Parameters.VariableParameters,
        label: 'SCALING.PROPERTIES.VARIABLE_PARAMETERS',
        showError: () => {
          return {
            isError: false,
          };
        },
        required: false,
      }),
      buildCustomParameterWithMetadata({
        // this is actually a list in the API
        parameter: SeeqNames.MaterializedTables.Parameters.VariableParameterStrings,
        label: 'SCALING.PROPERTIES.VARIABLE_PARAMETER_STRINGS',
        required: false,
        showError: () => {
          return {
            isError: false,
          };
        },
      }),
    ],
    columnRuleOutputToColumnRuleInput(
      formulaOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
      { scopedTo }: TableDefinitionAccessSettings = {},
    ): ColumnRuleFormulaCreatorInputV1 {
      const columnIndexes = getColumnIndexesForRuleWithMultipleInputColumns(formulaOutput, otherColumns);
      return {
        columnIndexes,
        description: formulaOutput.arguments.description,
        formula: formulaOutput.arguments.formula,
        // @ts-ignore - TODO CRAB-41437 identityId is a restricted parameter currently
        //  PermissionString and identityId are required together on the backend, so if only one is set, the column is
        //  uneditable. This is a quick fix until we unrestrict these arguments
        identityId: undefined,
        // @ts-ignore - see above explanation
        permissionString: undefined,
        name: formulaOutput.arguments.name,
        parameters: formulaOutput.arguments.parameters ? formulaOutput.arguments.parameters.split('|') : [],
        scopedTo,
        variableParameters: formulaOutput.arguments.variableParameters
          ? formulaOutput.arguments.variableParameters.split('|')
          : undefined,
        variableParameterStrings: formulaOutput.arguments.variableParameterStrings
          ? formulaOutput.arguments.variableParameterStrings.split('|')
          : undefined,
      };
    },
  },
  // --- Item Property ---
  {
    rule: SeeqNames.MaterializedTables.Rules.GetItemProperty,
    label: 'SCALING.RULES.ITEM_PROPERTY.NAME',
    description: 'SCALING.RULES.ITEM_PROPERTY.DESCRIPTION',
    // This rule is allowed on any column type
    allowedOnColumnTypes: [
      ColumnTypeEnum.UUID,
      ColumnTypeEnum.TEXT,
      ColumnTypeEnum.BOOLEAN,
      ColumnTypeEnum.TIMESTAMPTZ,
      ColumnTypeEnum.NUMERIC,
    ],
    allParameters: [
      // This is a columnDropdown input field that is required. It is used to select the column that we are getting the
      // property from
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndex,
        label: 'SCALING.PROPERTIES.COLUMN_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.UUID],
        required: true,
        isSearchable: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.columnIndex,
          };
        },
        getDefaultValue: (allColumnDefinitions) => {
          return allColumnDefinitions!.findIndex((col) => col.displayName === DEFAULT_ITEM_ID_COLUMN_NAME) + 1;
        },
      }),
      // This is a text input field that is required. It is used to specify the property name that we are fetching
      buildDefaultPropertyNameParameterWithMetadata(),
    ],
    columnRuleOutputToColumnRuleInput(
      itemPropertyOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
    ): ColumnRuleGetItemPropertyInputV1 {
      const columnIndex = getColumnIndexForRuleWithSingleInputColumn(itemPropertyOutput, otherColumns);
      return {
        columnIndex,
        propertyName: itemPropertyOutput.arguments.propertyName,
      };
    },
  },
  // --- Ancestor ---
  {
    rule: SeeqNames.MaterializedTables.Rules.Ancestor,
    label: 'SCALING.RULES.ANCESTOR.NAME',
    description: 'SCALING.RULES.ANCESTOR.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.UUID],
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndex,
        label: 'SCALING.PROPERTIES.COLUMN_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.UUID],
        required: true,
        isSearchable: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.columnIndex,
          };
        },
        getDefaultValue: (allColumnDefinitions) => {
          return allColumnDefinitions!.findIndex((col) => col.displayName === DEFAULT_ITEM_ID_COLUMN_NAME) + 1;
        },
      }),
      buildNumberParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Level,
        label: 'SCALING.PROPERTIES.LEVEL',
        minimum: 1,
        required: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.level || ruleState.level < 1,
            message: t('SCALING.RULES.ANCESTOR.ERRORS.MINIMUM_LEVEL', { minLevel: 0 }),
          };
        },
        getDefaultValue: () => 1,
      }),
    ],
    columnRuleOutputToColumnRuleInput(
      ancestorOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
    ): ColumnRuleAncestorInputV1 {
      const columnIndex = getColumnIndexForRuleWithSingleInputColumn(ancestorOutput, otherColumns);
      return {
        columnIndex,
        level: Number(ancestorOutput.arguments?.level),
      };
    },
  },
  // --- Descendant ---
  {
    rule: SeeqNames.MaterializedTables.Rules.Descendant,
    label: 'SCALING.RULES.DESCENDANT.NAME',
    description: 'SCALING.RULES.DESCENDANT.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.UUID],
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndex,
        label: 'SCALING.PROPERTIES.COLUMN_INPUT',
        required: true,
        allowedColumnInputTypes: [ColumnTypeEnum.UUID],
        isSearchable: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.columnIndex,
          };
        },
        getDefaultValue: (allColumnDefinitions) => {
          return allColumnDefinitions!.findIndex((col) => col.displayName === DEFAULT_ITEM_ID_COLUMN_NAME) + 1;
        },
      }),
      buildDefaultPropertyNameParameterWithMetadata(false),
      buildOptionDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.PropertyMatchOperator,
        label: 'SCALING.PROPERTY_INPUT.PROPERTY_OPERATOR',
        required: true,
        dropdownOptions: (Object.values(PropertyMatchOperatorEnum) as PropertyMatchOperatorEnum[]).map((enumValue) => {
          const label = PropertyMatchOperatorEnumToLabel[enumValue];
          const value = enumValue.toString();
          return { value, label };
        }),
        isSearchable: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.propertyMatchOperator,
          };
        },
        getDefaultValue: () => PropertyMatchOperatorEnum.STRINGCONTAINS.toString(),
      }),
      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.PropertyValue,
        label: 'SCALING.PROPERTY_INPUT.PROPERTY_VALUE',
        placeholder: 'SCALING.PROPERTY_INPUT.PROPERTY_VALUE_PLACEHOLDER',
        required: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.propertyValue,
          };
        },
      }),
      buildNumberParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Level,
        label: 'SCALING.PROPERTIES.LEVEL',
        minimum: 1,
        required: false,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !_.isNil(ruleState.level) && ruleState.level < 1,
            message: t('SCALING.RULES.DESCENDANT.ERRORS.MINIMUM_LEVEL', { minLevel: 0 }),
          };
        },
        getDefaultValue: () => 1,
      }),
      buildOptionDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ItemType,
        label: 'SCALING.PROPERTIES.ITEM_TYPE',
        placeholder: 'SCALING.PARAMETER_OPTIONAL_INPUT_PLACEHOLDER',
        required: false,
        dropdownOptions: ItemTypeOptions,
        showError: () => {
          return {
            isError: false, // In the future we should validate based on the item type passed in and the allowed
            // descendants of the item type
          };
        },
      }),
    ],
    columnRuleOutputToColumnRuleInput(
      descendantOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
    ): ColumnRuleDescendantInputV1 {
      const columnIndex = getColumnIndexForRuleWithSingleInputColumn(descendantOutput, otherColumns);
      return {
        columnIndex,
        itemType: descendantOutput.arguments.itemType,
        propertyMatchOperator: descendantOutput.arguments.propertyMatchOperator as unknown as PropertyMatchOperatorEnum,
        propertyName: descendantOutput.arguments.propertyName,
        propertyValue: descendantOutput.arguments.propertyValue,
      };
    },
  },
  // --- Constant ---
  {
    rule: SeeqNames.MaterializedTables.Rules.Constant.BaseConstant,
    label: 'SCALING.RULES.CONSTANT.NAME',
    description: 'SCALING.RULES.CONSTANT.DESCRIPTION',
    allowedOnColumnTypes: [
      ColumnTypeEnum.UUID,
      ColumnTypeEnum.TEXT,
      ColumnTypeEnum.BOOLEAN,
      ColumnTypeEnum.TIMESTAMPTZ,
      ColumnTypeEnum.NUMERIC,
    ],
    allParameters: [
      {
        parameter: SeeqNames.MaterializedTables.Parameters.Constant,
        label: 'SCALING.PROPERTIES.CONSTANT',
        input: {
          type: 'text', // this actually depends on the column type
          placeholder: 'SCALING.PARAMETER_INPUT_PLACEHOLDER',
        },
        required: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: ruleState.constant === undefined,
          };
        },
      },
    ],
    columnRuleOutputToColumnRuleInput(constantOutput: ColumnRuleOutputV1): ColumnRuleConstantInputV1 {
      return {
        [SeeqNames.MaterializedTables.Rules.Constant.StringConstant]: {
          constant: constantOutput.arguments['stringValue'],
        },
        [SeeqNames.MaterializedTables.Rules.Constant.NumericConstant]: {
          constant: Number(constantOutput.arguments['numericValue']),
        },
        [SeeqNames.MaterializedTables.Rules.Constant.BooleanConstant]: {
          constant: constantOutput.arguments['booleanValue'] === 'true', // coerce to boolean
        },
        [SeeqNames.MaterializedTables.Rules.Constant.UUIDConstant]: { constant: constantOutput.arguments['uuidValue'] },
        [SeeqNames.MaterializedTables.Rules.Constant.TimestampConstant]: {
          constant: constantOutput.arguments['timestampValue'],
        },
      }[constantOutput.rule] as ColumnRuleConstantInputV1;
    },
  },
  // --- Concat ---
  {
    rule: SeeqNames.MaterializedTables.Rules.ConcatColumns,
    label: 'SCALING.RULES.CONCAT.NAME',
    description: 'SCALING.RULES.CONCAT.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.TEXT],
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndexes,
        label: 'SCALING.PROPERTIES.COLUMN_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.TEXT],
        isMultiple: true,
        required: true,
        isSearchable: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.columnIndexes || ruleState.columnIndexes.length < 2,
          };
        },
      }),
    ],
    columnRuleOutputToColumnRuleInput(
      concatOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
    ): ColumnRuleConcatInputV1 {
      const columnIndexes = getColumnIndexesForRuleWithMultipleInputColumns(concatOutput, otherColumns);
      return {
        columnIndexes,
      };
    },
  },
  // --- Path ---
  {
    rule: SeeqNames.MaterializedTables.Rules.Path,
    label: 'SCALING.RULES.PATH.NAME',
    description: 'SCALING.RULES.PATH.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.TEXT],
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndex,
        label: 'SCALING.PROPERTIES.COLUMN_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.UUID],
        required: true,
        isSearchable: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.columnIndex,
          };
        },
        getDefaultValue: (allColumnDefinitions) => {
          return allColumnDefinitions!.findIndex((col) => col.displayName === DEFAULT_ITEM_ID_COLUMN_NAME) + 1;
        },
      }),
      buildNumberParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Level,
        label: 'SCALING.PROPERTIES.LEVEL',
        minimum: 0,
        required: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: _.isNil(ruleState.level) || ruleState.level < 0,
            message: t('SCALING.RULES.PATH.ERRORS.MINIMUM_LEVEL', { minLevel: 0 }),
          };
        },
        getDefaultValue: () => 1,
      }),
      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Separator,
        label: 'SCALING.PROPERTIES.SEPARATOR',
        placeholder: 'SCALING.PARAMETER_OPTIONAL_INPUT_PLACEHOLDER',
        required: false,
        showError: () => {
          return {
            isError: false,
          };
        },
      }),
    ],
    columnRuleOutputToColumnRuleInput(
      pathOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
    ): ColumnRulePathInputV1 {
      const columnIndex = getColumnIndexForRuleWithSingleInputColumn(pathOutput, otherColumns);
      return {
        columnIndex,
        level: Number(pathOutput.arguments?.level),
        separator: pathOutput.arguments.separator,
      };
    },
  },
  // Asset Tree Creator Pseudo Rule
  {
    rule: CREATE_TREE_PSUEDO_RULE,
    label: 'SCALING.RULES.ASSET_TREE_CREATOR.NAME',
    description: 'SCALING.RULES.ASSET_TREE_CREATOR.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.TEXT],
    allParameters: TreePathCreatorAllParameters,
    // @ts-ignore - This is a pseudo rule that is not actually a rule, in the future this may become an Action
    columnRuleOutputToColumnRuleInput: () => ({}),
  },
  // Asset Tree Update Tree from this table Pseudo Rule
  {
    rule: ADD_TO_TREE_PSUEDO_RULE,
    label: 'SCALING.RULES.ADD_TO_ASSET_TREE.NAME',
    description: 'SCALING.RULES.ADD_TO_ASSET_TREE.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.TEXT],
    allParameters: TreePathCreatorAllParameters,
    // @ts-ignore - This is a pseudo rule that is not actually a rule, in the future this may become an Action
    columnRuleOutputToColumnRuleInput: () => ({}),
  },
  // --- Tree Path Creator ---
  {
    rule: SeeqNames.MaterializedTables.Rules.TreePathCreator,
    label: 'SCALING.RULES.TREE_PATH_CREATOR.NAME',
    description: 'SCALING.RULES.TREE_PATH_CREATOR.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.TEXT],
    allParameters: TreePathCreatorAllParameters,
    columnRuleOutputToColumnRuleInput(
      treePathOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
    ): ColumnRuleTreePathCreatorInputV1 {
      const columnIndexes = getColumnIndexesForRuleWithMultipleInputColumns(treePathOutput, otherColumns);
      return {
        columnIndexes,
        separator: treePathOutput.arguments.separator,
      };
    },
  },
  // --- Asset Creator ---
  {
    rule: SeeqNames.MaterializedTables.Rules.AssetCreator,
    label: 'SCALING.RULES.ASSET_CREATOR.NAME',
    description: 'SCALING.RULES.ASSET_CREATOR.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.UUID],
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndex,
        label: 'SCALING.PROPERTIES.COLUMN_ASSET_NAME',
        allowedColumnInputTypes: [ColumnTypeEnum.TEXT],
        required: true,
        isSearchable: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.columnIndex,
          };
        },
      }),
      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Description,
        label: 'SCALING.PROPERTIES.DESCRIPTION',
        placeholder: 'SCALING.PARAMETER_OPTIONAL_INPUT_PLACEHOLDER',
        isResizable: true,
        required: false,
        showError: () => {
          return {
            isError: false,
          };
        },
      }),
      buildDefaultScopedToParameterWithMetadata(),
      buildDefaultIdentityIdParameterWithMetadata(),
      buildDefaultPermissionStringParameterWithMetadata(),
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndexDataId,
        label: 'SCALING.PROPERTIES.COLUMN_ASSET_ID_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.TEXT],
        required: false,
        isSearchable: true,
        showError: () => {
          return {
            isError: false,
          };
        },
        isFirstDropdown: false,
      }),
      buildBooleanParametersWithMetaData({
        parameter: SeeqNames.MaterializedTables.Parameters.IsRoot,
        label: 'SCALING.PROPERTIES.ROOT',
        required: false,
        showError: () => {
          return {
            isError: false,
          };
        },
      }),
    ],
    columnRuleOutputToColumnRuleInput(
      assetOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
      { scopedTo }: TableDefinitionAccessSettings = {},
    ): ColumnRuleAssetCreatorInputV1 {
      const columnIndexes = getColumnIndexesForRuleWithMultipleInputColumns(assetOutput, otherColumns);
      return {
        columnIndex: columnIndexes[0],
        description: assetOutput.arguments.description,
        isRoot: assetOutput.arguments.isRoot === 'true',
        // @ts-ignore - TODO CRAB-41437 identityId is a restricted parameter currently
        //  PermissionString and identityId are required together on the backend, so if only one is set, the column is
        //  uneditable. This is a quick fix until we unrestrict these arguments
        identityId: undefined,
        // @ts-ignore - see above explanation
        permissionString: undefined,
        scopedTo,
        columnIndexDataId: columnIndexes[1],
      };
    },
  },
  // --- Event Property ---
  {
    rule: SeeqNames.MaterializedTables.Rules.EventProperty,
    label: 'SCALING.RULES.EVENT_PROPERTY.NAME',
    description: 'SCALING.RULES.EVENT_PROPERTY.DESCRIPTION',
    allowedOnColumnTypes: [
      ColumnTypeEnum.UUID,
      ColumnTypeEnum.TEXT,
      ColumnTypeEnum.BOOLEAN,
      ColumnTypeEnum.TIMESTAMPTZ,
      ColumnTypeEnum.NUMERIC,
    ],
    allParameters: [buildDefaultPropertyNameParameterWithMetadata()],
    columnRuleOutputToColumnRuleInput: (ruleOutput) =>
      ({ propertyName: ruleOutput.arguments.propertyName } as ColumnRuleEventPropertyInputV1),
  },
  // --- Set Item Property ---
  {
    rule: SeeqNames.MaterializedTables.Rules.SetItemProperty,
    label: 'SCALING.RULES.SET_ITEM_PROPERTY.NAME',
    description: 'SCALING.RULES.SET_ITEM_PROPERTY.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.TEXT], // Since this rule currently only accepts a text property
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndexItem,
        label: 'SCALING.PROPERTIES.COLUMN_ITEM_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.UUID],
        required: true,
        isSearchable: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.columnIndexItem,
          };
        },
      }),

      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndexValue,
        label: 'SCALING.PROPERTIES.COLUMN_TEXT_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.TEXT],
        required: true,
        isSearchable: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.columnIndexValue,
          };
        },
        isFirstDropdown: false,
      }),
      buildDefaultPropertyNameParameterWithMetadata(),
    ],
    columnRuleOutputToColumnRuleInput(
      setItemPropertyOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
    ): ColumnRuleSetItemPropertyInputV1 {
      const columnIndexes = getColumnIndexesForRuleWithMultipleInputColumns(setItemPropertyOutput, otherColumns);
      // for this rule, the first input is the UUID column we are adding a property to, and the second input is the text
      // column we are getting the property value from
      const columnIndexItem = columnIndexes[0];
      const columnIndexValue = columnIndexes[1];
      return {
        columnIndexItem,
        columnIndexValue,
        propertyName: setItemPropertyOutput.arguments.propertyName,
      };
    },
  },
  // --- Path Search ---
  {
    rule: SeeqNames.MaterializedTables.Rules.PathSearch,
    label: 'SCALING.RULES.PATH_SEARCH.NAME',
    description: 'SCALING.RULES.PATH_SEARCH.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.UUID],
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndex,
        label: 'SCALING.PROPERTIES.COLUMN_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.TEXT],
        required: true,
        isSearchable: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.columnIndex,
          };
        },
        getDefaultValue: (allColumnDefinitions) => {
          return allColumnDefinitions!.findIndex((col) => col.displayName === DEFAULT_ITEM_ID_COLUMN_NAME) + 1;
        },
      }),
      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Separator,
        label: 'SCALING.PROPERTIES.SEPARATOR',
        placeholder: 'SCALING.PARAMETER_OPTIONAL_INPUT_PLACEHOLDER',
        required: false,
        showError: () => {
          return {
            isError: false,
          };
        },
      }),
    ],
    columnRuleOutputToColumnRuleInput(
      pathSearchOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
    ): ColumnRulePathSearchInputV1 {
      const columnIndex = getColumnIndexForRuleWithSingleInputColumn(pathSearchOutput, otherColumns);

      return {
        columnIndex,
        separator: pathSearchOutput.arguments.separator,
      };
    },
  },
  // --- Text Extractor ---
  {
    rule: SeeqNames.MaterializedTables.Rules.TextExtractor,
    label: 'SCALING.RULES.TEXT_EXTRACTOR.NAME',
    description: 'SCALING.RULES.TEXT_EXTRACTOR.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.TEXT],
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndex,
        label: 'SCALING.PROPERTIES.COLUMN_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.TEXT],
        required: true,
        isSearchable: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.columnIndex,
          };
        },
      }),
      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Regex,
        label: 'SCALING.PROPERTIES.REGEX',
        placeholder: 'SCALING.PARAMETER_INPUT_PLACEHOLDER',
        required: true,
        additionalInfo: {
          link: 'https://regex101.com/',
        },
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.regex,
          };
        },
      }),
    ],
    columnRuleOutputToColumnRuleInput(
      textExtractorOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
    ): ColumnRuleTextExtractorInputV1 {
      const columnIndex = getColumnIndexForRuleWithSingleInputColumn(textExtractorOutput, otherColumns);
      return {
        columnIndex,
        regex: textExtractorOutput.arguments.regex,
      };
    },
  },
  // --- Text Replacement ---
  {
    rule: SeeqNames.MaterializedTables.Rules.TextReplacement,
    label: 'SCALING.RULES.TEXT_REPLACEMENT.NAME',
    description: 'SCALING.RULES.TEXT_REPLACEMENT.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.TEXT],
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndexSourceText,
        label: 'SCALING.PROPERTIES.COLUMN_SOURCE_TEXT',
        allowedColumnInputTypes: [ColumnTypeEnum.TEXT],
        required: true,
        isSearchable: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.columnIndexSourceText,
          };
        },
      }),

      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Regex,
        label: 'SCALING.PROPERTIES.REGEX',
        placeholder: 'SCALING.PARAMETER_INPUT_PLACEHOLDER',
        required: true,
        additionalInfo: {
          link: 'https://regex101.com/',
        },
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.regex,
          };
        },
      }),

      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndexReplacement,
        label: 'SCALING.PROPERTIES.COLUMN_REPLACEMENT_TEXT',
        allowedColumnInputTypes: [
          ColumnTypeEnum.TEXT,
          ColumnTypeEnum.BOOLEAN,
          ColumnTypeEnum.NUMERIC,
          ColumnTypeEnum.TIMESTAMPTZ,
          ColumnTypeEnum.UUID,
        ],
        required: false,
        isSearchable: true,
        showError: () => {
          return {
            isError: false,
          };
        },
        isFirstDropdown: false,
      }),

      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Replacement,
        label: 'SCALING.PROPERTIES.REPLACEMENT_TEXT',
        placeholder: 'SCALING.PARAMETER_INPUT_PLACEHOLDER',
        required: false,
        showError: () => {
          return {
            isError: false,
          };
        },
      }),

      buildBooleanParametersWithMetaData({
        parameter: SeeqNames.MaterializedTables.Parameters.RemoveNonMatches,
        label: 'SCALING.PROPERTIES.REMOVE_NON_MATCHES',
        required: true,
        showError: () => {
          return {
            isError: false,
          };
        },
      }),
    ],
    columnRuleOutputToColumnRuleInput(
      textReplacementOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
    ): ColumnRuleTextReplacementInputV1 {
      const columnIndexes = getColumnIndexesForRuleWithMultipleInputColumns(textReplacementOutput, otherColumns);
      return {
        columnIndexSourceText: columnIndexes[0],
        columnIndexReplacement: columnIndexes[1],
        regex: textReplacementOutput.arguments.regex,
        replacement: textReplacementOutput.arguments.replacement,
        removeNonMatches: textReplacementOutput.arguments.removeNonMatches === 'true',
      };
    },
  },
  // --- Item Search ---
  {
    rule: SeeqNames.MaterializedTables.Rules.ItemSearch,
    label: 'SCALING.RULES.ITEM_SEARCH.NAME',
    description: 'SCALING.RULES.ITEM_SEARCH.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.UUID],
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndexes,
        label: 'SCALING.PROPERTIES.COLUMN_INPUT',
        required: true,
        isMultiple: true,
        allowedColumnInputTypes: [ColumnTypeEnum.UUID],
        isSearchable: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.columnIndexes,
          };
        },
      }),
      buildOptionDropdownParameterWithMetadata({
        parameter: 'propertyMatchOperators',
        label: 'SCALING.PROPERTIES.PROPERTY_MATCH_OPERATOR',
        required: true,
        isMultiple: true,
        dropdownOptions: [],
        isSearchable: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.propertyMatchOperators,
          };
        },
        getDefaultValue: () => [PropertyMatchOperatorEnum.EQUALS.toString()],
      }),
      buildOptionDropdownParameterWithMetadata({
        parameter: 'propertyNames',
        label: 'SCALING.PROPERTIES.PROPERTY_NAME',
        required: true,
        isMultiple: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.propertyNames,
          };
        },
        dropdownOptions: [],
        getDefaultValue: () => [SeeqNames.Properties.Name],
      }),
      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ItemTypes,
        label: 'SCALING.PROPERTIES.ITEM_TYPES',
        placeholder: 'SCALING.PARAMETER_OPTIONAL_INPUT_PLACEHOLDER',
        required: false,
        showError: () => {
          return {
            isError: false,
          };
        },
      }),
      buildDefaultScopedToParameterWithMetadata(),
      buildBooleanParametersWithMetaData({
        parameter: SeeqNames.MaterializedTables.Parameters.ExcludeGloballyScoped,
        label: 'SCALING.PROPERTIES.EXCLUDE_GLOBALLY_SCOPED',
        required: false,
        showError: () => {
          return {
            isError: false,
          };
        },
      }),
    ],
    columnRuleOutputToColumnRuleInput(
      itemSearchOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
    ): ColumnRuleItemSearchInputV1 {
      const columnIndexes = getColumnIndexesForRuleWithMultipleInputColumns(itemSearchOutput, otherColumns);

      return {
        columnIndexes,
        propertyNames: itemSearchOutput.arguments.propertyNames.split('|'),
        propertyMatchOperators: itemSearchOutput.arguments.propertyMatchOperators.split('|'),
        itemTypes: itemSearchOutput.arguments.itemTypes?.split('|'),
        scopedTo: itemSearchOutput.arguments.scopedTo,
        excludeGloballyScoped: itemSearchOutput.arguments.excludeGloballyScoped === 'true',
      };
    },
  },
  // --- Scalar Creator ---
  {
    rule: SeeqNames.MaterializedTables.Rules.ScalarCreator.BaseScalarCreator,
    label: 'SCALING.RULES.SCALAR_CREATOR.NAME',
    description: 'SCALING.RULES.SCALAR_CREATOR.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.UUID],
    allParameters: [
      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Name,
        label: 'SCALING.PROPERTIES.NAME',
        placeholder: 'SCALING.FORMULA_CREATOR.NAME_INPUT_PLACEHOLDER',
        required: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.name,
          };
        },
      }),
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndex,
        label: 'SCALING.PROPERTIES.COLUMN_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.NUMERIC, ColumnTypeEnum.TEXT],
        required: true,
        isSearchable: true,
        showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
          return {
            isError: !ruleState.columnIndex,
          };
        },
      }),
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndexDataId,
        label: 'SCALING.PROPERTIES.COLUMN_DATA_ID_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.TEXT],
        required: false,
        isSearchable: true,
        showError: () => {
          return {
            isError: false,
          };
        },
        isFirstDropdown: false,
      }),
      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Description,
        label: 'SCALING.PROPERTIES.DESCRIPTION',
        placeholder: 'SCALING.PROPERTIES.DESCRIPTION',
        isResizable: true,
        required: false,
        showError: () => {
          return {
            isError: false,
          };
        },
      }),
      // The following parameters are currently not exposed to the user, and so they are built as custom components
      // right now. If we decide to expose them to the user, we can change the input type to a standard input type
      // or to match a new input type that we create for them.
      buildDefaultScopedToParameterWithMetadata(),
      buildDefaultIdentityIdParameterWithMetadata(),
      buildDefaultPermissionStringParameterWithMetadata(),
    ],
    columnRuleOutputToColumnRuleInput(
      scalarCreatorOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
      { scopedTo }: TableDefinitionAccessSettings = {},
    ): ColumnRuleScalarCreatorInputV1 {
      const columnIndexes = getColumnIndexesForRuleWithMultipleInputColumns(scalarCreatorOutput, otherColumns);

      return {
        columnIndex: columnIndexes[0],
        columnIndexDataId: columnIndexes[1],
        description: scalarCreatorOutput.arguments.description,
        // @ts-ignore - TODO CRAB-41437 identityId is a restricted parameter currently
        //  PermissionString and identityId are required together on the backend, so if only one is set, the column is
        //  uneditable. This is a quick fix until we unrestrict these arguments
        identityId: undefined,
        // @ts-ignore - see above explanation
        permissionString: undefined,
        name: scalarCreatorOutput.arguments.name,
        scopedTo,
      };
    },
  },
];

/**
 * If you configure a parameter with this parameter builder, you will get a text input field in the UI.
 */
function buildTextParameterWithMetadata(
  metadata: Omit<TextInputOptions, 'type'> & Omit<ParameterWithMetadata, 'input'>,
): ParameterWithMetadata {
  const { parameter, label, placeholder, isResizable, required, additionalInfo, showError, getDefaultValue } = metadata;
  return {
    parameter,
    label,
    additionalInfo,
    input: {
      type: 'text',
      placeholder,
      isResizable,
    },
    required,
    showError,
    getDefaultValue,
  };
}

/**
 * This is useful for a parameter that is either a parameter not exposed through the UI because it is populated
 * through a custom UI (like 'parameters' in the formula creator rule)
 */
function buildCustomParameterWithMetadata(metadata: Omit<ParameterWithMetadata, 'input'>): ParameterWithMetadata {
  const { parameter, label, required, showError, getDefaultValue } = metadata;
  return {
    parameter,
    label,
    input: {
      type: 'custom',
    },
    required,
    showError,
    getDefaultValue,
  };
}

/**
 * If you configure a parameter with this parameter builder, you will get a number input field in the UI.
 */
function buildNumberParameterWithMetadata(
  metadata: Omit<NumberInputOptions, 'type'> & Omit<ParameterWithMetadata, 'input'>,
): ParameterWithMetadata {
  const { parameter, label, minimum, required, showError, getDefaultValue } = metadata;
  return {
    parameter,
    label,
    input: {
      type: 'number',
      minimum,
    },
    required,
    showError,
    getDefaultValue,
  };
}

/**
 * If you configure a parameter with this parameter builder, you will get a column dropdown input field in the UI
 * that is populated only by existing columns whose type is contained in {@link allowedColumnInputTypes}. If the
 * parameter in question can take multiple columns as input, like 'columnIndexes', set {@link isMultiple} to true.
 */
function buildColumnDropdownParameterWithMetadata(
  metadata: Omit<ColumnInputOptions, 'type'> & Omit<ParameterWithMetadata, 'input'>,
): ParameterWithMetadata {
  const {
    parameter,
    label,
    allowedColumnInputTypes,
    isMultiple,
    required,
    showError,
    isSearchable,
    getDefaultValue,
    isFirstDropdown = true,
  } = metadata;
  return {
    parameter,
    label,
    input: {
      type: 'columnDropdown',
      allowedColumnInputTypes,
      isMultiple,
      isSearchable,
    },
    required,
    showError,
    getDefaultValue,
    isFirstDropdown,
  };
}

/**
 * If you configure a parameter with this parameter builder, you will get a dropdown input field in the UI populated
 * with the options in {@link dropdownOptions}.
 */
function buildOptionDropdownParameterWithMetadata(
  metadata: Omit<DropdownInputOptions, 'type'> & Omit<ParameterWithMetadata, 'input'>,
): ParameterWithMetadata {
  const {
    parameter,
    label,
    dropdownOptions,
    isMultiple,
    required,
    placeholder,
    showError,
    isSearchable,
    getDefaultValue,
  } = metadata;
  return {
    parameter,
    label,
    input: {
      type: 'optionDropdown',
      placeholder,
      dropdownOptions,
      isMultiple,
      isSearchable,
      showPlaceholder: true,
    },
    required,
    showError,
    getDefaultValue,
  };
}

/**
 * If you configure a parameter with this parameter builder, you will get a boolean input field in the UI.
 */
function buildBooleanParametersWithMetaData(metadata: Omit<ParameterWithMetadata, 'input'>): ParameterWithMetadata {
  const { parameter, label, required, showError, getDefaultValue } = metadata;
  return {
    parameter,
    label,
    input: {
      type: 'boolean',
    },
    required,
    showError,
    getDefaultValue,
  };
}

/**
 * Useful only for ${@link SeeqNames.MaterializedTables.Parameters.PropertyName}, you will get a dropdown input field in
 * the UI with the default label and placeholder for ${@link SeeqNames.MaterializedTables.Parameters.PropertyName}.
 */
function buildDefaultPropertyNameParameterWithMetadata(showPlaceholder = true): ParameterWithMetadata {
  return {
    parameter: SeeqNames.MaterializedTables.Parameters.PropertyName,
    label: 'SCALING.PROPERTY_INPUT.PROPERTY_NAME',
    input: {
      type: 'optionDropdown',
      placeholder: 'SCALING.PROPERTY_INPUT.PROPERTY_NAME_PLACEHOLDER',
      dropdownOptions: PropertyNameOptions,
      showPlaceholder,
    },
    required: true,
    showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
      return {
        isError: !ruleState.propertyName,
      };
    },
    getDefaultValue: () => PropertyNameOptions[0].value,
  };
}

/**
 * Useful only for ${@link SeeqNames.MaterializedTables.Parameters.ScopeTo}, this will hide the field from the user.
 */
function buildDefaultScopedToParameterWithMetadata(): ParameterWithMetadata {
  return {
    parameter: SeeqNames.MaterializedTables.Parameters.ScopedTo,
    label: 'SCALING.PROPERTIES.SCOPED_TO',
    input: {
      type: 'custom', // this is actually a UUID in the API
    },
    required: false,
    showError: (ruleState: Partial<CombinedColumnRuleInputParameters>) => {
      return {
        isError: false,
      };
    },
  };
}

/**
 * Useful only for ${@link SeeqNames.MaterializedTables.Parameters.IdentityId}, this will hide the field from the user.
 */
function buildDefaultIdentityIdParameterWithMetadata(): ParameterWithMetadata {
  return {
    parameter: SeeqNames.MaterializedTables.Parameters.IdentityId,
    label: 'SCALING.PROPERTIES.IDENTITY_ID',
    input: {
      type: 'custom', // this is actually a UUID in the API
    },
    required: false,
    showError: () => {
      return {
        isError: false,
      };
    },
  };
}

/**
 * Useful only for ${@link SeeqNames.MaterializedTables.Parameters.PermissionString}, this will hide the field from the
 * user.
 */
function buildDefaultPermissionStringParameterWithMetadata(): ParameterWithMetadata {
  return {
    parameter: SeeqNames.MaterializedTables.Parameters.PermissionString,
    label: 'SCALING.PROPERTIES.PERMISSION_STRING',
    input: {
      type: 'custom',
    },
    required: false,
    showError: () => {
      return {
        isError: false,
      };
    },
  };
}

function getColumnIndexForRuleWithSingleInputColumn(
  descendantOutput: ColumnRuleOutputV1,
  otherColumns: ColumnDefinitionOutputV1[],
) {
  const columnInputId = descendantOutput.inputs?.[0];
  const columnIndex = otherColumns.findIndex((columnDef) => columnDef.id === columnInputId);
  return columnIndex + 1; // 1-indexed
}

function getColumnIndexesForRuleWithMultipleInputColumns(
  ruleOutput: ColumnRuleOutputV1,
  otherColumns: ColumnDefinitionOutputV1[],
) {
  const columnInputIds = ruleOutput.inputs || [];
  return columnInputIds.map((columnId) => {
    const columnIndex = otherColumns.findIndex((columnDef) => columnDef.id === columnId);

    return columnIndex + 1; // 1-indexed
  });
}
