import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { findItemIn, getTrendItemScopedTo, getTrendStores } from '@/trend/trendDataHelper.utilities';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useStateWithRef } from '@/core/hooks/useStateWithRef.hook';
import { InputGroup } from 'react-bootstrap';
import { MAX_NAME_LENGTH } from '@/main/app.constants';
import { PREVIEW_ID } from '@/trendData/trendData.constants';
import { ColorPicker } from '@/workbook/ColorPicker.organism';
import { IconWithSpinner } from '@/core/IconWithSpinner.atom';
import { FormError } from '@/core/FormError.atom';
import { useIsMounted } from '@/core/hooks/useIsMounted.hook';
import { setTrendItemColor } from '@/trendData/trend.actions';
import { getDefaultName } from '@/utilities/formula.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { ToolPanelAiButton } from '@/aiAssistant/ToolPanelAiButton.atom';
import { TextField } from '@seeqdev/qomponents';
import { useDidMount } from 'rooks';

interface SearchTitleProps {
  name: string;
  id: string;
  setSearchName: (name: string) => void;
  searchIconClass: string;
  defaultName: string;
  onColorChange?: (color: string) => void;
  color?: string;
  fromFormBuilder?: boolean;
  className?: any;
}

const SearchTitleUnwrapped: React.FunctionComponent<SearchTitleProps> = (props) => {
  const { id, setSearchName, searchIconClass, defaultName, color, onColorChange, fromFormBuilder } = props;
  const { t } = useTranslation();
  const [name, setName] = useStateWithRef(props.name ? props.name : '');
  const [loadingDefaultName, setLoadingDefaultName] = useState(false);
  const isMounted = useIsMounted();

  useEffect(() => {
    setName(props.name);
    // exhaustive deps does not work well with setters from custom hooks
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.name]);

  useDidMount(() => {
    if (_.isEmpty(name.current)) {
      setLoadingDefaultName(true);
      getDefaultName(t(defaultName), getTrendItemScopedTo(id), [SeeqNames.API.Flags.ExcludeGloballyScoped]).then(
        (defaultName) => {
          if (isMounted.current) {
            setLoadingDefaultName(false);
            if (_.isEmpty(name.current)) {
              setName(defaultName);
              setSearchName(defaultName);
            }
          }
        },
      );
    }

    if (!onColorChange) {
      return;
    }

    const currentId = id || PREVIEW_ID;
    const trendStores = getTrendStores();
    const existingItem: any = findItemIn(trendStores, currentId);
    const nextColor = trendStores?.[0].findNextColor();
    const itemColor = existingItem?.color ?? nextColor;

    if (color !== itemColor) {
      onColorChange(itemColor);
    }
  });

  const updateName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setName(name);
    setSearchName(name);
  };

  return (
    <div>
      <h4 className="card-title flexColumnContainer flexAlignCenter searchTitleDiv">
        <IconWithSpinner
          testId="toolIcon"
          spinning={loadingDefaultName}
          icon={classNames('pr5', searchIconClass)}
          large={true}
        />
        <InputGroup className="width-maximum searchTitleInput flexColumnContainer flexNoWrap pr5">
          <TextField
            id="name"
            size="sm"
            value={name.current ? name.current : ''}
            showError={(name.current?.length || 0) > MAX_NAME_LENGTH.TOOL}
            maxLength={MAX_NAME_LENGTH.TOOL}
            onChange={updateName}
            // No focus events types in the browser or React match this but it seemingly works
            onFocus={(e: any) => e.target.select()}
            testId="searchTitleInput"
            extraClassNames={classNames('truncatedLabel', 'width-maximum', props.className)}
            required={true}
          />
          <InputGroup.Append>
            {onColorChange && (
              <InputGroup.Text>
                <ColorPicker
                  color={color}
                  itemId={id}
                  placement="bottom"
                  notifyOnSelect={(_, newColor) => {
                    onColorChange(newColor);
                    setTrendItemColor(id, newColor);
                  }}
                />
              </InputGroup.Text>
            )}
          </InputGroup.Append>
        </InputGroup>
        <ToolPanelAiButton />
      </h4>

      {!fromFormBuilder && _.isEmpty(_.trim(name.current)) && !loadingDefaultName && (
        <FormError extraClassNames="ml25" errorText="FORM.REQUIRED_FIELD" />
      )}

      {!fromFormBuilder && name.current?.length > MAX_NAME_LENGTH.TOOL && (
        <FormError
          extraClassNames="ml25"
          errorText="NAME_LENGTH_ERROR"
          errorParameters={{ count: MAX_NAME_LENGTH.TOOL }}
        />
      )}
    </div>
  );
};

export const SearchTitle = React.memo(
  SearchTitleUnwrapped,
  (prev, next) =>
    !(
      prev.searchIconClass !== next.searchIconClass ||
      prev.className !== next.className ||
      prev.name !== next.name ||
      prev.color !== next.color
    ),
);
