import { Icon } from '@seeqdev/qomponents';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface SearchResultRemovableProps {
  itemId: string;
  itemIcon: React.ReactNode;
  itemName: string;
  itemAncestors?: string;
  onRemove: (itemId: string) => void;
}

export const SearchResultRemovable: React.FunctionComponent<SearchResultRemovableProps> = ({
  itemId,
  itemIcon,
  itemName,
  itemAncestors,
  onRemove,
}) => {
  const { t } = useTranslation();

  return (
    <div key={`key_${itemId}`} className="flexRowContainer flexFill removableSearchResult">
      <div className="flexColumnContainer flexCenter flexFill">
        {itemIcon}
        <div className="searchResultName flexFill">
          <span className="simple-word-break">{itemName}</span>
          {itemAncestors && (
            <span className="xsmall ml5 pb2 sq-fairly-dark-gray text-italic simple-word-break">{itemAncestors}</span>
          )}
        </div>
        <Icon
          icon="fa-close"
          type="text"
          onClick={() => onRemove(itemId)}
          testId="removeSearchResult"
          tooltip={t('SEARCH_DATA.REMOVABLE_RESULT_TOOLTIP')}
          extraClassNames="mr10 cursorPointer"
        />
      </div>
    </div>
  );
};
