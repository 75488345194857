// @ts-strict-ignore
import _ from 'lodash';
import {
  getNumericTickPositions,
  getYAxisIdFromItem,
  yAxisFormatter,
  yAxisStringFormatter,
} from '@/utilities/chartHelper.utilities';
import {
  createPlotBandAxisDefinition,
  createYAxisDefinition,
  getCapsuleAxisDefinition,
  getCapsuleAxisId,
} from '@/utilities/label.utilities';
import i18next from 'i18next';
import { CHART_FONT_STYLE, ChartGetters } from '@/chart/chart.constants';
import { formatDuration } from '@/datetime/dateTime.utilities';
import { ITEM_TYPES, TREND_COLORS } from '@/trendData/trendData.constants';
import { getDefaultCapsuleDataLabelSettings } from '@/trend/trendDataHelper.utilities';
import { DEFAULT_AXIS_LABEL_COLOR } from '@/trend/trendViewer/trendViewer.constants';
import { CursorsService } from '@/trend/trendViewer/cursors.service';
import { COLUMN_BUFFER, LABEL_CONDITION_ID } from '@/trend/compareView.constants';
import { sqTrendStore, sqWorkbenchStore } from '@/core/core.stores';
import { CursorData, DurationDataForCursors, TrendDataForChart } from '@/annotation/interactiveContent.types';
import { ChartLaneOptions } from '@/utilities/chart.types';
import { chartLanes } from '@/utilities/chartLanes';

/**
 * Utility functions to help draw the chart in "compare" mode
 */

// Unrelated tests will fail if this isn't a getter due to module initialization order
const getLabelCapsuleAxisId = () => getCapsuleAxisId(LABEL_CONDITION_ID);

export function xAxisFormatter() {
  return formatDuration(this.value as number, { simplify: true });
}

export const getCompareViewChartOptions = (): Highcharts.Options => ({
  chart: {
    animation: false,
    alignTicks: false,
    resetZoomButton: { theme: { display: 'none' } },
    events: {},
    backgroundColor: 'transparent',
    style: CHART_FONT_STYLE,
  },
  legend: { enabled: false },
  title: { text: null },
  credits: { enabled: false },
  plotOptions: {
    series: {
      animation: false,
      cursor: 'pointer',
      linecap: 'square',
      connectNulls: false,
      dataGrouping: { enabled: false },
      turboThreshold: 0,
      boostThreshold: 0,
      states: {
        hover: {
          lineWidthPlus: 0,
        },
        inactive: {
          enabled: false,
        },
      },
      dataLabels: {
        useHTML: true,
        align: 'left',
        verticalAlign: 'middle',
        allowOverlap: false,
        style: {
          fontFamily: 'inherit',
          fontSize: '10px',
          fontWeight: 'normal',
        },
      },
    },
    line: {
      boostThreshold: 0,
      lineWidth: 1,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
      point: {
        events: {},
      },
      states: {
        hover: {
          halo: { size: 0 },
          lineWidthPlus: 0,
        },
      },
    },
  },
  tooltip: {
    enabled: true,
    formatter() {
      if (this.series.userOptions.itemType !== ITEM_TYPES.CAPSULE) {
        return false;
      }
      return this.series.data[0].dataLabelString;
    },
  },
  series: [],
  xAxis: [],
  yAxis: [createPlotBandAxisDefinition()],
});

export const omitData = (series) => _.map(series, (series) => _.omit(series, 'data'));

// From https://stackoverflow.com/a/68116026
export const isEqualOmitFunctions = (object1, object2) =>
  _.isEqualWith(object1, object2, (value1, value2) =>
    _.isFunction(value1) && _.isFunction(value2) ? value1.toString() === value2.toString() : undefined,
  );

export function chartMouseMove(
  e: MouseEvent & {
    chartX: number;
  },
  params: {
    sqCursors: CursorsService;
    chart: Highcharts.Chart;
    sqTrendStoreData: TrendDataForChart;
    items: any[];
    sqDurationStoreData: DurationDataForCursors;
    longestCapsuleSeriesDuration: number;
    sqCursorStoreData: CursorData;
    timezoneName: string;
    capsuleLaneHeight: number;
    colorMap: Record<string, string> | undefined;
  },
) {
  const {
    sqCursors,
    chart,
    items,
    sqTrendStoreData,
    timezoneName,
    sqDurationStoreData,
    sqCursorStoreData,
    capsuleLaneHeight,
    longestCapsuleSeriesDuration,
    colorMap,
  } = params;
  if (!chart) {
    return;
  }

  const chartRect = chart.container.getBoundingClientRect();
  let yValues = [];

  const xAxis = _.find(chart.xAxis, (axis) => axis.pos <= e.chartX && axis.pos + axis.len >= e.chartX);
  // If we're in between axes, we don't need to do anything
  if (!xAxis) {
    return;
  }
  const xValue = xAxis.toValue(e.clientX - chartRect.left, false);
  const itemsUsingAxis = _.chain(items)
    .filter((item) => xAxis.userOptions.id === getXAxisIdFromColumn(item.column))
    .thru((items) => (colorMap ? _.map(items, (item) => ({ ...item, color: colorMap[item.colorBy] })) : items))
    .value();

  // Don't try to calculate pointerValues when the cursor is in the y-axis area
  if (e.chartX >= 0) {
    yValues = sqCursors.calculatePointerValues(chart, xValue, itemsUsingAxis, sqTrendStoreData, xAxis);
    yValues = sqCursors.reduceYValues(
      yValues,
      items,
      chartLanes.getChartSeriesDisplayHeight({ chart, capsuleLaneHeight }),
    );
  }

  if (!_.isNaN(xValue)) {
    sqCursors.drawHoverCursor({
      chart,
      xPixel: e.chartX,
      xValue,
      yValues,
      capsuleLaneHeight,
      timezoneName,
      itemsWithLaneInfo: itemsUsingAxis,
      darkMode: sqWorkbenchStore.darkMode,
      durationData: sqDurationStoreData,
      cursorData: sqCursorStoreData,
      trendData: sqTrendStoreData,
      longestCapsuleSeriesDuration,
      xAxisOverride: xAxis,
      updateXPixel: false,
    });
  }
}

export function getColumnWidth({ columnCount, width, totalDuration = 0, duration = 0 }) {
  if (columnCount > 0) {
    const columnBufferWidth = (columnCount - 1) * COLUMN_BUFFER;

    if (totalDuration === 0) {
      return (width - columnBufferWidth) / columnCount;
    } else {
      return (width - columnBufferWidth) * (duration / totalDuration);
    }
  } else {
    return width;
  }
}

export function getXAxisIdFromColumn(column: string) {
  return `xAxis-${_.kebabCase(column)}`;
}

export function createXAxisDefinition({ column }): Highcharts.XAxisOptions {
  return {
    id: getXAxisIdFromColumn(column),
    type: 'datetime',
    ordinal: false,
    offset: 0,
    startOnTick: false,
    endOnTick: false,
    minRange: 1,
    minTickInterval: 0,
    tickLength: 5,
    tickPositioner() {
      return (
        _.chain([0])
          .concat(this.tickPositions)
          .filter((tick) => tick >= 0)
          // The rightmost tick label can overlap with the leftmost tick label of the next column
          .dropRight()
          .value()
      );
    },
    dateTimeLabelFormats: {
      millisecond: '%l:%M:%S.%L %P',
      second: '%l:%M:%S %P',
      minute: '%l:%M %P',
      hour: '%l:%M %P',
      day: '%b %e',
      week: '%b %e',
      month: "%b '%y",
      year: '%Y',
    },
    labels: {
      enabled: true,
      overflow: 'allow',
      formatter: xAxisFormatter,
      rotation: -60,
      style: {
        color: DEFAULT_AXIS_LABEL_COLOR,
        fontSize: '12px',
        fontFamily: 'Helvetica, sans-serif',
        paddingTop: '0px',
      },
    },
    visible: true,
  };
}

export const getColorMap = (series) =>
  _.chain(series)
    .map('colorBy')
    .uniq()
    .reject(_.isNil)
    .map((colorBy, index) => [colorBy, TREND_COLORS[index % TREND_COLORS.length]])
    .fromPairs()
    .value();

export function getDisplayedColumns(items): string[] {
  return _.chain(items).sortBy('startTime').map('column').reject(_.isNil).uniq().value();
}

export const getOrderedColumns = (series: any[], firstColumn: string) => {
  const displayedColumns = getDisplayedColumns(series);
  const startIndex = _.indexOf(displayedColumns, firstColumn);
  if (startIndex < 1) {
    return displayedColumns;
  }

  return _.concat(_.slice(displayedColumns, startIndex), _.slice(displayedColumns, 0, startIndex));
};

type GetNewConfigParams = {
  items: any[];
  firstColumn: string;
  chart: Highcharts.Chart;
  colorMap: Record<string, string>;
  laneOptions: ChartLaneOptions & ChartGetters;
  isSignalColorMode: boolean;
};

export const getNewConfig = ({
  items,
  firstColumn,
  chart,
  colorMap,
  laneOptions,
  isSignalColorMode,
}: GetNewConfigParams) => {
  const columns = getOrderedColumns(items, firstColumn);

  // Create initial axes
  const xAxes = _.map(columns, (column) => createXAxisDefinition({ column }));

  const yAxes: Highcharts.YAxisOptions[] = _.chain(items)
    .filter((item) => item.itemType === ITEM_TYPES.SERIES)
    .map((signal) =>
      createYAxisDefinition({
        item: signal,
        yAxisStringFormatter: yAxisStringFormatter(laneOptions),
        yAxisFormatter: yAxisFormatter(laneOptions),
        tickPositioner: getNumericTickPositions(laneOptions),
        trendValues: sqTrendStore,
      }),
    )
    .concat(createPlotBandAxisDefinition())
    .sortBy('lane')
    .value();

  const capsule = _.find(items, (item) => item.itemType === ITEM_TYPES.CAPSULE);
  if (capsule) {
    yAxes.push({
      ...getCapsuleAxisDefinition(capsule, getLabelCapsuleAxisId()),
    });
  }

  // Add axis and tooltip information to series and calculate the longest duration for each group of signals
  let modifiedSeries = _.filter(items, (item) => item.itemType === ITEM_TYPES.CAPSULE);
  let axesDurations = {};

  _.chain(items)
    .filter((item) => item.itemType === ITEM_TYPES.SERIES)
    .groupBy('column')
    .forEach((group) => {
      const xAxisId = getXAxisIdFromColumn(group[0].column);
      const xAxisIndex = _.findIndex(xAxes, (axis) => axis.id === xAxisId);
      if (xAxisIndex > -1) {
        axesDurations[xAxisIndex] = _.maxBy(group, 'duration')?.duration;
      }

      _.forEach(group, (item) => {
        if (xAxisIndex >= 0) {
          modifiedSeries.push({
            ...item,
            xAxis: xAxisId,
            yAxis: getYAxisIdFromItem(item),
            color: isSignalColorMode ? item.color : colorMap[item.colorBy],
          });
        }
      });
    })
    .value();

  // Set the width and left properties for the axes
  const totalDuration = _.sum(_.values(axesDurations));
  let left = chart.plotLeft;
  const plotWidth = chart?.plotWidth;
  _.forEach(xAxes, (axis, index) => {
    const width = getColumnWidth({
      columnCount: _.size(columns),
      width: plotWidth,
      totalDuration,
      duration: axesDurations[index],
    });

    _.assign(axis, { width, left });
    left += width + COLUMN_BUFFER;
  });

  return { xAxis: xAxes, series: modifiedSeries, yAxis: yAxes };
};

export function getDefaultCompareViewPropertyValue(value: string | undefined | null): string {
  return value ?? i18next.t('COMPARE_VIEW.NO_PROPERTY_FOUND');
}

const STEP_COUNT = 10;

export function getLabelCapsules(signals: any[]): Highcharts.YAxisOptions[] {
  return _.chain(signals)
    .groupBy('column')
    .map((group) => {
      const max = _.reduce(
        group,
        (accumulator, signal) =>
          signal.data.length >= 2
            ? // In trendSeries.utilities::getSignalData, in certain conditions we'll modify the last point such that
              // instead of a 2 element array it is an object with x/y values. This is a workaround to handle that case.
              Math.max(_.last(signal.data)[0] ?? (_.last(signal.data) as any).x, accumulator)
            : accumulator,
        Number.MIN_VALUE,
      );
      const signal = _.head(group);
      const dataStep = max / STEP_COUNT;

      return {
        id: `${LABEL_CONDITION_ID}_${signal.column}`,
        capsuleSetName: LABEL_CONDITION_ID,
        capsuleSetId: LABEL_CONDITION_ID,
        yAxis: getLabelCapsuleAxisId(),
        xAxis: getXAxisIdFromColumn(signal.column),
        anyCapsulesSelected: false,
        color: '#999',
        isAggregated: false,
        itemType: ITEM_TYPES.CAPSULE,
        lineWidth: 12,
        selected: false,
        yValue: 1,
        dataLabels: {
          ...getDefaultCapsuleDataLabelSettings(),
          labelNames: [signal.column],
          enabled: true,
          formatter() {
            // Only the first point has a dataLabelString
            if (!this.point.dataLabelString) {
              return null;
            }

            // Constrain the width to ensure long labels don't run outside the capsule
            const lastPoint = _.last(this.series.data) as any;
            const widthStyle = `width: ${lastPoint.plotX - this.point.plotX}px`;
            return `<span style="${widthStyle}">${this.point.dataLabelString}</span>`;
          },
        },
        states: {
          hover: {
            enabled: false,
          },
        },
        data: [
          {
            x: 0,
            y: 1,
            dataLabelString: signal.column,
            labelText: signal.column,
          },
          ..._.chain(_.range(1, STEP_COUNT))
            .map((num) => ({ x: dataStep * num, y: 1 }))
            .value(),
          { x: max, y: 1 },
          { x: max, y: null },
        ],
      };
    })
    .value();
}
