import React, { Suspense } from 'react';
import { CKEditorProps } from '@/annotation/CKEditor.organism';
import { LoadingFallback } from '@/main/LoadingFallback.atom';

const CKEditor = React.lazy(() => import('@/defaultExportShims/CKEditor'));

export const CKEditorAsync: React.FunctionComponent<CKEditorProps> = (props) => {
  return (
    <Suspense fallback={<LoadingFallback />}>
      <CKEditor {...props}></CKEditor>
    </Suspense>
  );
};
