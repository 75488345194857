import { SeeqNames } from '@/main/app.constants.seeqnames';
import { addPendingRequest, removePendingRequest } from '@/requests/pendingRequests.utilities';
import { extractGuids } from '@/utilities/utilities';
import { generateRequestId } from '@/utilities/http.utilities';
import { SeeqAxiosResponse, SeeqInternalAxiosRequestConfig } from '@/requests/axios.utilities';

export function cancellationResponseInterceptor(response: SeeqAxiosResponse) {
  removeRequestCancellation(response);
  return response;
}

export function cancellationResponseErrorInterceptor(response: SeeqAxiosResponse) {
  removeRequestCancellation(response);
  return Promise.reject(response);
}

function removeRequestCancellation(response: SeeqAxiosResponse) {
  if (response.config?.headers) {
    const requestId = response.config.headers[SeeqNames.API.Headers.RequestId];
    if (requestId) {
      removePendingRequest(requestId);
    }
  }
  return response;
}

function cancellable(url?: string): boolean {
  const nonCancellable = [
    '/requests/', // Used to cancel requests
    '/error-log', // Logs errors
    '/subscriptions', // Subscribes to websocket events
  ].find((x) => url?.includes(x));

  return !nonCancellable;
}

export function cancellationRequestInterceptor(config: SeeqInternalAxiosRequestConfig) {
  if (config.headers) {
    let requestId = config.headers[SeeqNames.API.Headers.RequestId];
    if (!requestId) {
      requestId = generateRequestId();
      config.headers[SeeqNames.API.Headers.RequestId] = requestId;
    }

    const groups = config.cancellationGroup
      ? extractGuids(config.url ?? '')
          .concat(extractGuids(JSON.stringify(config.params)))
          .concat([config.cancellationGroup])
      : undefined;

    if (groups || cancellable(config.url)) {
      config.timeout = addPendingRequest(requestId, config, groups);
    }
  }

  return config;
}
