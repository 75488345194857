import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { InputGroup } from 'react-bootstrap';
import { Button, Icon, TextField } from '@seeqdev/qomponents';

interface ClearableInputProps {
  /** identifier, provided to the filterTable function as the first parameter */
  field: string;
  /** text displayed in the input field */
  searchValue: string;
  /** called whenever the text in the input field changes */
  onInputChange: (field: string, value: string) => void;
  /** use a custom icon instead of checkmark */
  iconClassName?: string;
  /** true if the input box should be greyed out and disabled */
  disabled?: boolean;
  /** if provided, a checkmark or custom icon is displayed in the box, and clicking
   it or pressing enter will trigger the callback */
  enterCallback?: () => void;
  /** if provided, overrides the default clear behavior, which is to call the onChange callback with an empty string
   *  as input followed by the enterCallback.  This won't work if the enterCallback depends on a state change in the
   *  parent effected by the onChange callback; in such a case, the clearCallback may be used to mimic the enterCallback
   *  logic that would occur after the intended effects of the onChange callback on the parent. Whether the
   *  clearCallback is provided or not, clicking the clearBtn will always call onChange with an empty string before
   *  calling either the enterCallback or clearCallback; however, if the clearCallback is provided, the onMouseDown
   *  event handler for the clear button calls preventDefault on the event.*/
  clearCallback?: () => void;
  /** if provided, trigger the callback if input loses focus */
  onBlurCallback?: () => void;
  /** if provided, determined if the input field will be automatically focused on */
  autoFocus?: boolean;
  /** if provided, overrides the default placeholder key - make sure to pass a key to be translated, not a specific
   *  string! */
  placeholder?: string;
  /** if provided, determines if check will be shown when enterCallback is provided as well*/
  showCheck?: boolean;
}

/**
 * This component renders a text input field that displays a "delete" icon in the far right once text has been entered.
 */
export const ClearableInput: React.FunctionComponent<ClearableInputProps> = ({
  field,
  onInputChange,
  searchValue,
  disabled,
  iconClassName = 'fa-check',
  enterCallback,
  clearCallback,
  onBlurCallback,
  autoFocus,
  placeholder,
  showCheck = true,
}) => {
  const { t } = useTranslation();
  const formControlPlaceholder = placeholder ? t(placeholder) : t('ADMIN.FILTER_BY');

  return (
    <InputGroup className="flexColumnContainer flexNoWrap">
      <TextField
        extraClassNames={`displayFlex tableWrapper ${searchValue ? 'btrr0 bbrr0' : ''}`}
        readonly={disabled}
        testId={`clearableInput_${field}`}
        value={searchValue ?? ''}
        type="text"
        placeholder={formControlPlaceholder}
        onKeyDown={(e: React.KeyboardEvent) => e.key === 'Enter' && enterCallback?.()}
        onChange={({ target: { value } }) => onInputChange(field, value)}
        onBlur={() => onBlurCallback?.()}
        autoFocus={autoFocus}
      />
      <InputGroup.Append className="height-34 p-0">
        {showCheck && enterCallback && (
          <InputGroup.Text className="height-34 p-0 border-0">
            <Button
              testId={`enterBtn_${field}`}
              extraClassNames="height-34"
              label={<Icon icon={iconClassName} type="text" />}
              onClick={enterCallback}
            />
          </InputGroup.Text>
        )}

        {searchValue ? (
          <InputGroup.Text className="height-34 p-0 border-0">
            <Button
              testId={`clearBtn_${field}`}
              extraClassNames="height-34"
              label={<Icon icon="fa-times" type="text" />}
              preventBlur={!_.isNil(clearCallback)}
              onClick={() => {
                onInputChange(field, '');
                if (!_.isNil(clearCallback)) {
                  clearCallback();
                } else {
                  enterCallback?.();
                }
              }}
            />
          </InputGroup.Text>
        ) : null}
      </InputGroup.Append>
    </InputGroup>
  );
};
