import { Alert } from '@seeqdev/qomponents';
import React from 'react';

interface AlertWarningProps {
  children: React.ReactNode;
}

export const AlertWarning: React.FunctionComponent<AlertWarningProps> = ({ children }) => {
  return (
    <Alert dismissible={false} variant="warning">
      {children}
    </Alert>
  );
};
