/**
 * All stores that are shared throughout the app should be created here. Currently this is every store in the app,
 * but can be broken out into smaller files as smaller stand-alone React apps are extracted.
 */
import { flux } from '@/core/flux.module';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { AdministrationStore } from '@/administration/administration.store';
import { PdfExportStore } from '@/reportEditor/pdfExport.store';
import { DatasourcesStore } from '@/administration/datasources/datasources.store';
import { AssetGroupStore } from '@/assetGroupEditor/assetGroup.store';
import { LicenseManagementStore } from '@/licenseManagement/licenseManagement.store';
import { InvestigateStore } from '@/toolSelection/investigate.store';
import { PluginStore } from '@/plugin/plugin.store';
import { WorkbookStore } from '@/workbook/workbook.store';
import { DurationStore } from '@/trendData/duration.store';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { HomeScreenStore } from '@/homescreen/homescreen.store';
import { AnnotationStore } from '@/annotation/annotation.store';
import { WorkstepsStore } from '@/worksteps/worksteps.store';
import { SystemWarningStore } from '@/systemWarning/systemWarning.store';
import { ExploreFolderModalStore } from '@/explorer/exploreFolderModal.store';
import { TimebarStore } from '@/trend/trendViewer/timebar.store';
import { PluginsAdminStore } from '@/administration/pluginsAdmin.store';
import { ExploreWorkbookModalStore } from '@/explorer/exploreWorkbookModal.store';
import { AxiosStore } from '@/requests/axios.store';
import { ModalSearchStore } from '@/search/modalSearch.store';
import { SearchStore } from '@/search/search.store';
import { ConditionWithPropertiesStore } from '@/tools/conditionWithProperties/conditionWithProperties.store';
import { ValueSearchStore } from '@/trendData/valueSearch.store';
import { CompositeSearchStore } from '@/tools/compositeSearch/compositeSearch.store';
import { PredictionPanelStore } from '@/tools/prediction/predictionPanel.store';
import { ItemPropertiesStore } from '@/tools/itemProperties/propertiesPanel.store';
import { PeriodicConditionStore } from '@/tools/periodicCondition/periodicCondition.store';
import { ManualSignalStore } from '@/tools/manualSignal/manualSignal.store';
import { AggregationBinStore } from '@/tools/histogram/aggregationBin.store';
import { FftStore } from '@/tools/frequencyAnalysis/fft.store';
import { ProfileSearchStore } from '@/tools/profileSearch/profileSearch.store';
import { DigitalFilterStore } from '@/tools/digitalFilter/digitalFilter.store';
import { SignalSmoothingStore } from '@/tools/signalSmoothing/signalSmoothing.store';
import { SignalFromConditionStore } from '@/tools/signalFromCondition/signalFromCondition.store';
import { TrendMetricStore } from '@/trendData/trendMetric.store';
import { DisplayToolStore } from '@/tools/display/displayTool.store';
import { ReferencePanelStore } from '@/tools/referencePanel/referencePanel.store';
import { ReportStore } from '@/reportEditor/report.store';
import { TrendTableStore } from '@/trendData/trendTable.store';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { TrendScalarStore } from '@/trendData/trendScalar.store';
import { ImportDatafileStore } from '@/tools/importDatafile/importDatafile.store';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { CapsuleGroupStore } from '@/tools/manualCondition/capsuleGroup.store';
import { CursorStore } from '@/trendData/cursor.store';
import { TreemapStore } from '@/treemap/treemap.store';
import { TrendConditionStore } from '@/trendData/trendConditionStore.store';
import { TrendStore } from '@/trendData/trend.store';
import { ExportExcelPanelStore } from '@/tools/exportExcel/exportExcelPanel.store';
import { TableBuilderStore } from '@/tableBuilder/tableBuilder.store';
import { ReportContentStore } from '@/reportEditor/reportContent.store';
import { ScatterPlotStore } from '@/scatterPlot/scatterPlot.store';
import { ExportODataPanelStore } from '@/tools/exportOData/exportODataPanel.store';
import { FormulaToolStore } from '@/tools/formula/formulaTool.store';
import { ThresholdMetricStore } from '@/tools/thresholdMetric/thresholdMetric.store';
import { StateSynchronizer } from '@/services/stateSynchronizer.service';
import { ManualConditionStore } from '@/tools/manualCondition/manualCondition.store';
import { ScatterConditionStore } from '@/tools/scatterPlotSelection/scatterCondition.store';
import { ScorecardStore } from '@/tableBuilder/scorecard.store';
import { ViewODataExportsStore } from '@/tools/viewODataExports/viewODataExports.store';
import { isDebugStoresEnabled, reduxDevtoolsConnection } from '@/utilities/reduxDevtools.utilities';
import { DashboardStore } from '@/dashboard/dashboard.store';
import { AiAssistantStore } from '@/aiAssistant/aiAssistant.store';
import { ItemFinderStore } from '@/itemFinder/itemFinder.store';
import { TableDefinitionStore } from '@/tableDefinitionEditor/tableDefinition.store';
import { LayoutStore } from '@/main/layout.store';
import { WorkbenchLayoutStore } from '@/main/workbenchLayout.store';
import { AddOnToolStore } from '@/homescreen/addOnTool.store';
import { SelfOrganizingMapsStore } from '@/tools/selfOrganizingMaps/selfOrganizingMaps.store';
import { VantageTableStore } from '@/vantage/vantageTable.store';
import { VantageTrendStore } from '@/vantage/vantageTrend.store';
import { VantageContextStore } from '@/vantage/vantageContext.store';

export const sqStateSynchronizer = new StateSynchronizer(flux);
flux.setDispatchCallback((handler, payload, option) => {
  if (isDebugStoresEnabled) {
    reduxDevtoolsConnection?.send({ type: handler, payload }, flux.dispatcher.storeInstances);
  }

  sqStateSynchronizer.push(option);
});

export const sqAdministrationStore = flux.createStore(AdministrationStore);
export const sqLicenseManagementStore = flux.createStore(LicenseManagementStore);
export const sqDatasourcesStore = flux.createStore(DatasourcesStore);
export const sqAssetGroupStore = flux.createStore(AssetGroupStore);
export const sqWorkbenchStore = flux.createStore(WorkbenchStore);
export const sqPdfExportStore = flux.createStore(PdfExportStore);
export const sqInvestigateStore = flux.createStore(InvestigateStore);
export const sqPluginStore = flux.createStore(PluginStore);
export const sqAddOnToolStore = flux.createStore(AddOnToolStore);
export const sqWorkbookStore = flux.createStore(WorkbookStore);
export const sqDurationStore = flux.createStore(DurationStore);
export const sqHomeScreenStore = flux.createStore(HomeScreenStore);
export const sqAnnotationStore = flux.createStore(AnnotationStore);
export const sqWorkstepsStore = flux.createStore(WorkstepsStore);
export const sqTimebarStore = flux.createStore(TimebarStore);
export const sqSystemWarningStore = flux.createStore(SystemWarningStore);
export const sqExploreFolderModalStore = flux.createStore(ExploreFolderModalStore);
export const sqExploreWorkbookModalStore = flux.createStore(ExploreWorkbookModalStore);
export const sqPluginsAdminStore = flux.createStore(PluginsAdminStore);
export const sqWorksheetStore = flux.createStore(WorksheetStore);
export const sqAxiosStore = flux.createStore(AxiosStore);
export const sqModalSearchStore = flux.createStore(ModalSearchStore);
export const sqSearchStore = flux.createStore(SearchStore);
export const sqCompositeSearchStore = flux.createStore(CompositeSearchStore);
export const sqConditionWithPropertiesStore = flux.createStore(ConditionWithPropertiesStore);
export const sqValueSearchStore = flux.createStore(ValueSearchStore);
export const sqItemPropertiesStore = flux.createStore(ItemPropertiesStore);
export const sqManualSignalStore = flux.createStore(ManualSignalStore);
export const sqManualConditionStore = flux.createStore(ManualConditionStore);
export const sqFftStore = flux.createStore(FftStore);
export const sqAggregationBinStore = flux.createStore(AggregationBinStore);
export const sqSignalFromConditionStore = flux.createStore(SignalFromConditionStore);
export const sqPeriodicConditionStore = flux.createStore(PeriodicConditionStore);
export const sqProfileSearchStore = flux.createStore(ProfileSearchStore);
export const sqPredictionPanelStore = flux.createStore(PredictionPanelStore);
export const sqSignalSmoothingStore = flux.createStore(SignalSmoothingStore);
export const sqDigitalFilterStore = flux.createStore(DigitalFilterStore);
export const sqTrendMetricStore = flux.createStore(TrendMetricStore);
export const sqTrendTableStore = flux.createStore(TrendTableStore);
export const sqReferencePanelStore = flux.createStore(ReferencePanelStore);
export const sqDisplayToolStore = flux.createStore(DisplayToolStore);
export const sqTrendScalarStore = flux.createStore(TrendScalarStore);
export const sqReportStore = flux.createStore(ReportStore);
export const sqTrendSeriesStore = flux.createStore(TrendSeriesStore);
export const sqImportDatafileStore = flux.createStore(ImportDatafileStore);
export const sqTrendCapsuleStore = flux.createStore(TrendCapsuleStore);
export const sqCapsuleGroupStore = flux.createStore(CapsuleGroupStore);
export const sqCursorStore = flux.createStore(CursorStore);
export const sqTrendConditionStore = flux.createStore(TrendConditionStore);
export const sqTrendStore = flux.createStore(TrendStore);
export const sqTreemapStore = flux.createStore(TreemapStore);
export const sqExportExcelPanelStore = flux.createStore(ExportExcelPanelStore);
export const sqReportContentStore = flux.createStore(ReportContentStore);
export const sqScatterPlotStore = flux.createStore(ScatterPlotStore);
export const sqExportODataPanelStore = flux.createStore(ExportODataPanelStore);
export const sqTableBuilderStore = flux.createStore(TableBuilderStore);
export const sqFormulaToolStore = flux.createStore(FormulaToolStore);
export const sqThresholdMetricStore = flux.createStore(ThresholdMetricStore);
export const sqScatterConditionStore = flux.createStore(ScatterConditionStore);
export const sqScorecardStore = flux.createStore(ScorecardStore);
export const sqViewODataExportsStore = flux.createStore(ViewODataExportsStore);
export const sqDashboardStore = flux.createStore(DashboardStore);
export const sqAiAssistantStore = flux.createStore(AiAssistantStore);
export const sqItemFinderStore = flux.createStore(ItemFinderStore);
export const sqTableDefinitionStore = flux.createStore(TableDefinitionStore);
export const sqSelfOrganizingMapsStore = flux.createStore(SelfOrganizingMapsStore);
export const sqVantageContextStore = flux.createStore(VantageContextStore);
export const sqVantageTrendStore = flux.createStore(VantageTrendStore);
export const sqVantageTableStore = flux.createStore(VantageTableStore);

export const sqLayoutStore = flux.createStore(LayoutStore);
export const sqWorkbenchLayoutStore = flux.createStore(WorkbenchLayoutStore);
