import { PersistenceLevel, Store } from '@/core/flux.service';
import _ from 'lodash';
import { AnyProperty } from '@/utilities.types';
import { ContextCategoryFilters, ContextSchema } from '@/vantage/types/Context.types';

export class VantageContextStore extends Store {
  persistenceLevel: PersistenceLevel = 'WORKSHEET';
  static readonly storeName = 'sqVantageContextStore';

  initialize(): void {
    this.state = this.immutable({
      activeContext: [],
      pinnedExtensions: [],
      contextSearchFilterText: '',
      contextCategoryFilters: {
        kind: [],
        extension: [],
        seeqUser: [],
      },
      historyOpen: true,
    });
  }

  get contextSearchFilterText(): string {
    return this.state.get('contextSearchFilterText');
  }

  get contextCategoryFilters(): ContextCategoryFilters {
    return this.state.get('contextCategoryFilters');
  }

  get activeContext(): ContextSchema[] {
    return this.state.get('activeContext');
  }

  get pinnedExtensions(): string[] {
    return this.state.get('pinnedExtensions');
  }

  get historyOpen(): boolean {
    return this.state.get('historyOpen');
  }

  dehydrate() {
    return _.omit(this.state.serialize(), 'activeContext');
  }

  rehydrate(dehydratedState: AnyProperty) {
    this.state.merge(dehydratedState);
  }

  protected readonly handlers = {
    SET_VANTAGE_ACTIVE_CONTEXT: (payload: { activeContext: ContextSchema[] }) =>
      this.state.set('activeContext', payload.activeContext),
    SET_VANTAGE_PINNED_EXTENSIONS: (payload: { pinnedExtensions: string[] }) =>
      this.state.set('pinnedExtensions', payload.pinnedExtensions),
    SET_VANTAGE_CONTEXT_SEARCH_FILTER: (payload: { contextSearchFilterText: string }) =>
      this.state.set('contextSearchFilterText', payload.contextSearchFilterText),
    SET_VANTAGE_CONTEXT_CATEGORIES_FILTER: (payload: { contextCategoryFilters: ContextCategoryFilters }) =>
      this.state.set('contextCategoryFilters', payload.contextCategoryFilters),
    SET_VANTAGE_TIMELINE_OPEN: (payload: { isOpen: boolean }) => this.state.set('historyOpen', payload.isOpen),
  };
}
