import { PersistenceLevel, Store } from '@/core/flux.service';

export class AxiosStore extends Store {
  persistenceLevel: PersistenceLevel = 'NONE';
  static readonly storeName = 'sqAxiosStore';

  initialize() {
    this.state = this.immutable({
      pendingRequests: new Array<string>(),
    });
  }

  get pendingRequests(): number {
    return this.state.get('pendingRequests').length;
  }

  // A traditional flux handler is not used because this is invoked via axios interceptors which may already be calling
  // flux dispatch which results in an error
  addRequest(requestId?: string) {
    if (requestId) {
      this.state.push('pendingRequests', requestId);
    }
  }

  // A traditional flux handler is not used because this is invoked via axios interceptors which may already be calling
  // flux dispatch which results in an error
  removeRequest(requestId?: string) {
    if (requestId) {
      const current: string[] = this.state.get('pendingRequests');
      const updated = current.filter((id) => id !== requestId);
      this.state.set('pendingRequests', updated);
    }
  }

  protected readonly handlers = {};
}
