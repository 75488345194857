/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.1.0-v202412070338-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqTreesApiClass {
 
   constructor() {}

  /**
   * @summary Add the specified items as child nodes of the specified parents within the specified tree. If an item is already a member of this tree, it will move from its current parent to the specified parent. The children of the items being moved will move with each parent. When x-sq-async-job header is set to 'true' the operation runs as an async job that may be queried using the Jobs API.
   */
  public batchMoveNodesToParents(
    body?: models.AssetTreeBatchInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/trees/assets/batch`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemBatchOutputV1>;
  }

  /**
   * @summary Create or update an asset tree
   */
  public createTree(
    body?: models.AssetGroupInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/trees/assets/createTree`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AssetGroupOutputV1>;
  }

  /**
   * @summary Get a collection of all available tree types
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getAllTreeTypes(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/trees`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemPreviewListV1>;
  }

  /**
   * @summary Get the ancestors and children of the specified item within the context of this tree
   * @param {string} id - ID of the specified item
   * @param {Array<string>} [scope] - The IDs of the workbooks to which the items are scoped&lt;br&gt;If scope is set to the Empty GUID then the scoped items are excluded.
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   * @param {boolean} [includeDescendants=false] - If the children of the children should be retrieved. Note that a 400 error will be returned if the total number of children and descendants exceeds the specified limit
   * @param {boolean} [excludeGloballyScoped=false] - Exclude globally scoped items.
   */
  public getTree(
    {
      id,
      scope,
      offset,
      limit,
      includeDescendants,
      excludeGloballyScoped
    } : {
      id: string,
      scope?: Array<string>,
      offset?: number,
      limit?: number,
      includeDescendants?: boolean,
      excludeGloballyScoped?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/trees/assets/${encodeURIComponent(String(id))}`,

      params: omitBy({
        ['scope']: scope,
        ['offset']: offset,
        ['limit']: limit,
        ['includeDescendants']: includeDescendants,
        ['excludeGloballyScoped']: excludeGloballyScoped
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AssetTreeOutputV1>;
  }

  /**
   * @summary Get a collection of root nodes for the asset tree
   * @param {Array<string>} [scope] - The IDs of the workbooks to which the items are scoped&lt;br&gt;If the scope is set to the Empty GUID, then the scoped items are excluded.
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   * @param {Array<string>} [properties] - A list of property names and values to filter on.  Arguments should be passed in in the following format Property&#x3D;Value, with each new property-value pair on a new line. The option to filter out a node by a property can be done by using !&#x3D;.
   * @param {boolean} [excludeGloballyScoped=false] - Exclude globally scoped items.
   */
  public getTreeRootNodes(
    {
      scope,
      offset,
      limit,
      properties,
      excludeGloballyScoped
    } : {
      scope?: Array<string>,
      offset?: number,
      limit?: number,
      properties?: Array<string>,
      excludeGloballyScoped?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/trees/assets`,

      params: omitBy({
        ['scope']: scope,
        ['offset']: offset,
        ['limit']: limit,
        ['properties']: properties,
        ['excludeGloballyScoped']: excludeGloballyScoped
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AssetTreeOutputV1>;
  }

  /**
   * @summary Add the specified items as child nodes of the specified parent within the specified tree. If an item is already a member of this tree, it will move from its current parent to the specified parent. The children of the item being moved will move with it.
   * @param {string} parentId - ID of the parent node
   */
  public moveNodesToParent(
    body: models.ItemIdListInputV1,
    {
      parentId
    } : {
      parentId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(parentId)) {
      throw new Error("'parentId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/trees/assets/${encodeURIComponent(String(parentId))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Add the specified items as root nodes of the specified tree. If an item is already a member of this tree, it will move to the root and its children will move with it
   */
  public moveNodesToRootOfTree(
    body: models.ItemIdListInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/trees/assets`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Trigger a rebuild of an asset tree search index in the background, starting at the specified root asset. This can be a time consuming and resource intensive operation, especially on large trees. The reindexing is performed in the background, and the API call will return as soon as the rebuild has been triggered.
   * @param {string} rootAssetId - ID the root asset of the tree to rebuild
   * @deprecated 
   */
  public rebuildSearchIndex(
    {
      rootAssetId
    } : {
      rootAssetId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(rootAssetId)) {
      throw new Error("'rootAssetId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/trees/assets/${encodeURIComponent(String(rootAssetId))}/rebuildSearchIndex`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Remove an item and its children from the tree. (These items will still exist but not as part of this tree.)
   * @param {string} id - ID of the node to remove
   */
  public removeNodeFromTree(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/trees/assets/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Scale a tree item across its asset tree. Asset-swapped copies of the item will be inserted under all assets at the same tree level as its current parent.
   * @param {string} id - ID of the item to be scaled
   */
  public scaleAcrossTree(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/trees/assets/${encodeURIComponent(String(id))}/scale`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ScaleAcrossTreeOutputV1>;
  }

  /**
   * @summary Build a treemap
   * @param {string} start - The start time under which to compute conditions for the treemap.
   * @param {string} end - The end time under which to compute conditions for the treemap.
   * @param {Array<string>} conditionIds - The IDs of the conditions that will be used to create the treemap; must be in order of priority, where the first condition is the highest priority item.
   * @param {string} swapId - The ID of the asset to swap each treemap leaf node with.
   * @param {string} parentId - The ID of the parent asset to build the treemap from.
   * @param {Array<string>} [parameters] - Parameters for the display formulas. Each parameter should have a format of &#x27;name&#x3D;id&#x27; where &#x27;name&#x27; is the variable identifier, without the leading $ sign, and &#x27;id&#x27; is the ID of the item referenced by the variable
   * @param {Array<string>} [formulas] - A list of display scalar formulas
   */
  public treemap(
    {
      start,
      end,
      conditionIds,
      swapId,
      parentId,
      parameters,
      formulas
    } : {
      start: string,
      end: string,
      conditionIds: Array<string>,
      swapId: string,
      parentId: string,
      parameters?: Array<string>,
      formulas?: Array<string>
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(start)) {
      throw new Error("'start' parameter required");
    }

    if (isNil(end)) {
      throw new Error("'end' parameter required");
    }

    if (isNil(conditionIds)) {
      throw new Error("'conditionIds' parameter required");
    }

    if (isNil(swapId)) {
      throw new Error("'swapId' parameter required");
    }

    if (isNil(parentId)) {
      throw new Error("'parentId' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/trees/treemap`,

      params: omitBy({
        ['start']: start,
        ['end']: end,
        ['conditionIds']: conditionIds,
        ['swapId']: swapId,
        ['parentId']: parentId,
        ['parameters']: parameters,
        ['formulas']: formulas
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.TreemapOutputV1>;
  }

  /**
   * @summary Update an asset tree
   */
  public updateTree(
    body?: models.AssetGroupInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/trees/assets/updateTree`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AssetGroupOutputV1>;
  }

}


export const sqTreesApi = new sqTreesApiClass();
