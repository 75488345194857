import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { sqWorkbenchStore } from '@/core/core.stores';
import { DatasourcesStatus } from '@/footer/DatasourcesStatus.organism';
import { useTranslation } from 'react-i18next';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { subscribe } from '@/utilities/socket.utilities';
import { seeqFullReleaseIdentifier } from '@/services/systemConfiguration.utilities';
import { ButtonWithPopover, Icon } from '@seeqdev/qomponents';
import { isAdmin } from '@/services/authorization.service';
import { SupportRequestModal } from '@/supportRequest/SupportRequestModal';
import { useModalManager } from '@/core/hooks/useModalManager.hook';

export interface RequestProgressMessage {
  consumption: string;
  serverLoad: {
    description: string;
  };
}

export const Footer: React.FC = () => {
  const { t } = useTranslation();
  const [threadPoolUsage, setThreadPoolUsage] = useState('');
  const [showSupportRequestModal, setShowSupportRequestModal] = useModalManager(SupportRequestModal);
  const interactiveSessionId = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.interactiveSessionId);
  const isDev = process.env.NODE_ENV === 'development';

  useEffect(() => {
    return subscribe({
      channelId: [SeeqNames.Channels.RequestsProgress, sqWorkbenchStore.interactiveSessionId],
      onMessage: (message: RequestProgressMessage) =>
        setThreadPoolUsage(`${message.serverLoad.description} ${message.consumption}`),
      useSubscriptionsApi: false,
    });
  }, [interactiveSessionId]);

  return (
    <footer id="footer" data-testid="footer" className="mainFooter flexColumnContainer pr10">
      <div
        data-testid="feedback"
        className="cursorPointer sq-call-out pl10 pr10 pt4 pb4 mr10"
        onClick={() => setShowSupportRequestModal(true)}>
        <Icon icon="fa-comment-dots" extraClassNames="mr5" type="white" />
        {t('FEEDBACK')}
      </div>
      <div id="seeqVersion" className="inlineBlock pt4 pb4">
        {seeqFullReleaseIdentifier()} {isDev && '[DEV]'}
      </div>
      <div className="flexColumnContainer autoMarginLeft">
        <DatasourcesStatus />

        {isAdmin() && !_.isEmpty(threadPoolUsage) && (
          <ButtonWithPopover
            placement="top"
            hasArrow
            tooltipPlacement="top"
            tooltip={t('THREAD_POOL_USAGE.TOOLTIP')}
            trigger={
              <div className="text-nowrap flexColumnContainer flexAlignCenter cursorPointer">
                <span className="list-group-item-text cursorDefault ml5 mr5">|</span>
                <span className="list-group-item-text pr5">{t('THREAD_POOL_USAGE.TITLE')}</span>
              </div>
            }>
            <div id="threadPoolUsagePopOver" className="datasourcesPopup min-width-500 pt10">
              <div>
                <div className="flexColumnContainer flexCenter">
                  <div className="text-pre-wrap text-monospace sq-text-color">{threadPoolUsage}</div>
                </div>
                <div className="text-center pt7 pb7">
                  <a
                    href="https://support.seeq.com/kb/latest/cloud/troubleshooting-performance#TroubleshootingPerformance-ThreadPoolUsagePop-Up"
                    target="_blank"
                    rel="noopener">
                    <span>{t('THREAD_POOL_USAGE.KB_LINK_DESCRIPTION')}</span>
                  </a>
                </div>
              </div>
            </div>
          </ButtonWithPopover>
        )}
      </div>
      {showSupportRequestModal && <SupportRequestModal onClose={() => setShowSupportRequestModal(false)} />}
    </footer>
  );
};
