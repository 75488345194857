import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button, Icon } from '@seeqdev/qomponents';
import { IconWithSpinner } from '@/core/IconWithSpinner.atom';
import { SearchResult } from '@/search/SearchResult.molecule';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { canModifyWorkbook } from '@/services/authorization.service';
import { sqLicenseManagementStore, sqWorkbookStore, sqWorksheetStore } from '@/core/core.stores';
import { SearchModes, SearchPanes } from '@/search/search.constants';
import { clearSelection, getStoreForPane, setMode } from '@/search/search.actions';
import { setDisplayAssetGroupEditor, setDisplayTableDefinitionEditor } from '@/worksheet/worksheet.actions';
import { LOAD_STATUS } from '@/workbook/workbook.constants';
import { AnyProperty } from '@/utilities.types';
import { resetItemFinder } from '@/itemFinder/itemFinder.actions';
import { doTrack } from '@/track/track.service';
import { LIMIT_IN_OVERVIEW } from '@/search/search.utilities';

export interface SearchGroupProps {
  loadStatus: keyof typeof LOAD_STATUS;
  label: string;
  mode: SearchModes;
  searchMode: SearchModes;
  searchTypes: string[];
  scopeIds: string[];
  restrictExploration: boolean;
  onErrorClick: () => void;
  onClickItem: (item: AnyProperty) => void;
  items: any[];
  pane: SearchPanes;
  indicateSelection?: boolean;
  selectedItemId?: string;
  searchResultIcons: (item: AnyProperty) => Promise<React.ReactNode>;
  noItemsMessage?: string;
  isSelectingAsset?: boolean;
  predefinedSearchTypes?: string[];
  totalItemsCount?: number;
}

export const SearchGroup: React.FunctionComponent<SearchGroupProps> = ({
  items,
  loadStatus,
  label,
  mode,
  pane,
  onErrorClick,
  onClickItem,
  searchResultIcons,
  searchMode,
  searchTypes,
  scopeIds,
  restrictExploration,
  noItemsMessage,
  indicateSelection = false,
  selectedItemId,
  isSelectingAsset = false,
  predefinedSearchTypes,
  totalItemsCount = 0,
}) => {
  const { t } = useTranslation();
  const displayAssetGroupEditor = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.displayAssetGroupEditor);
  const displayTableDefinitionEditor = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.displayTableDefinitionView);
  const store = getStoreForPane(pane);
  const selectedItemIds = useFluxPath(store, () => store.selectedItemIds);
  const showCreateAssetGroupButton =
    searchMode === 'asset-groups' &&
    sqLicenseManagementStore.hasAssetGroups() &&
    pane === 'main' &&
    canModifyWorkbook(sqWorkbookStore.workbook);
  const showCreateTableDefinitionButton =
    searchMode === 'table-definitions' && pane === 'main' && canModifyWorkbook(sqWorkbookStore.workbook);
  const searchingUsages = mode === 'usages';

  const renderSetModeIconButton = (testId?: string) => {
    return (
      <Icon
        testId={testId ?? 'setModeIconButton'}
        icon="fa-chevron-right"
        extraClassNames="text-interactive pl5 pr5"
        onClick={() => setMode(pane, searchMode, searchTypes, restrictExploration, scopeIds)}
      />
    );
  };

  const renderAssetGroupButton = (
    <div>
      <Button
        testId="createButton"
        label={t('SEARCH_DATA.CREATE')}
        onClick={() => {
          setDisplayAssetGroupEditor(true);
        }}
        disabled={displayAssetGroupEditor}
        extraClassNames="sq-btn-xs pr5 mr5"
      />
      {renderSetModeIconButton('assetGroupMoreButton')}
    </div>
  );

  const renderTableDefinitionButton = (
    <div>
      <Button
        testId="tableDefinitionCreateButton"
        label={t('SEARCH_DATA.CREATE')}
        onClick={() => {
          doTrack('Scaling Table Configuration', 'Open Create Item Finder Modal');
          setDisplayTableDefinitionEditor(true);
          resetItemFinder();
        }}
        disabled={displayTableDefinitionEditor}
        extraClassNames="sq-btn-xs pr5 mr5"
      />
      {renderSetModeIconButton('scalingMoreButton')}
    </div>
  );

  const renderButton = () => {
    if (showCreateAssetGroupButton) {
      return renderAssetGroupButton;
    }
    if (showCreateTableDefinitionButton) {
      return renderTableDefinitionButton;
    }
    return renderSetModeIconButton();
  };

  const nonClickableHeading = (
    <span
      data-testid="nonClickableHeading"
      className="flexColumnContainer flexSpaceBetween flexAlignCenter heading text-interactive fs15">
      {t(`SEARCH_DATA.${label}`)}
      {showCreateAssetGroupButton && renderAssetGroupButton}
      {showCreateTableDefinitionButton && renderTableDefinitionButton}
    </span>
  );

  return (
    <div>
      {loadStatus === LOAD_STATUS.LOADING && !searchingUsages && (
        <div data-testid="loadingStatus">
          {nonClickableHeading}
          <div className="pt10 pb10 flexNoGrowNoShrink flexColumnContainer flexCenter">
            <IconWithSpinner spinning={true} large={true} />
            <span className="mb5 ml5 mt5">{t(`SEARCH_DATA.LOADING_${label}`)}</span>
          </div>
        </div>
      )}

      {loadStatus === LOAD_STATUS.ERROR && !searchingUsages && (
        <div data-testid="errorStatus">
          {nonClickableHeading}
          <div className="flexColumnContainer sq-text-danger cursorPointer pt3 pb6" onClick={onErrorClick}>
            <Icon icon="fa-exclamation-triangle" type="danger" extraClassNames="flexCenter pl5 pr10 pt2" />
            <div className="flexFill pl5">
              {t(`SEARCH_DATA.LOAD_${label}_FAILED`)}
              <br />
              <span className="text-underline">{t('SEARCH_DATA.RETRY')}</span>
            </div>
          </div>
        </div>
      )}

      {items.length === 0 &&
        ((mode === searchMode && !searchingUsages) || mode === 'overview') &&
        noItemsMessage &&
        pane === 'main' && (
          <div>
            {nonClickableHeading}
            <div className="m5">{t(noItemsMessage)}</div>
          </div>
        )}

      {items.length > 0 && (mode === searchMode || mode === 'overview') && (
        <div>
          {mode === 'overview' && (
            <div data-testid="modeSet" className="flexColumnContainer flexSpaceBetween flexAlignCenter heading">
              <span
                className="text-interactive flexFill fs15"
                onClick={() => setMode(pane, searchMode, searchTypes, restrictExploration, scopeIds)}>
                {t(`SEARCH_DATA.${label}`)} {totalItemsCount > 0 && `(${totalItemsCount})`}
              </span>
              {renderButton()}
            </div>
          )}

          {_.map(items, (item: any) => (
            <SearchResult
              isSelectingAsset={isSelectingAsset}
              searchTypes={predefinedSearchTypes}
              key={item.id}
              item={item}
              items={items}
              searchResultIcons={searchResultIcons}
              itemSelected={_.indexOf(selectedItemIds, item.id) > -1}
              onClickItem={() => {
                clearSelection(); // unselect selected items
                onClickItem(item);
              }}
              isSelected={indicateSelection && selectedItemId === item.id}
            />
          ))}
        </div>
      )}
      {mode === 'overview' && totalItemsCount > LIMIT_IN_OVERVIEW && (
        <Button
          testId="viewMoreButton"
          label={t('SEARCH_DATA.VIEW_MORE')}
          onClick={() => setMode(pane, searchMode, searchTypes, restrictExploration, scopeIds)}
          extraClassNames="viewMoreLinkButton"
        />
      )}
    </div>
  );
};
