import React from 'react';
import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import { getFormFieldWrapperProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import { getCKEditorInstance } from '@/annotation/ckEditor.utilities';
import { CKEditorAsync } from '@/annotation/CKEditorAsync.organism';
import { useDebounce } from '@/core/hooks/useDebounce.hook';
import { DEBOUNCE } from '@/core/core.constants';

export interface CKEditorFormComponentProps extends ValidatingFormComponent<string | number> {
  component: 'CKEditorFormComponent';
  onChange: (value: string | number) => void;
  placeholder?: string;
  id?: string;
  autoFocus?: boolean;
  displayError?: boolean;
  customErrorText?: string;
  labelIcon?: string;
  labelIconAction?: () => void;
  labelIconTooltip?: string;
  labelClassNames?: string;
}

export const CKEditorFormComponent: React.FunctionComponent<CKEditorFormComponentProps> = (props) => {
  const {
    value,
    name,
    placeholder,
    extendValidation,
    validation,
    onChange,
    testId = 'formControl',
    required,
    customErrorText,
    labelIcon,
    labelIconAction,
    labelIconTooltip,
    labelClassNames,
  } = props;

  const defaultValidation = (value: string) => !!(required && _.isEmpty(_.trim(value)));
  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);
  const formState = useForm().getState();

  const showError =
    _.has(formState.errors, name) &&
    formState.hasValidationErrors &&
    ((formState.modified && formState.modified[name]) || formState.submitFailed);

  const toolbar = [
    'heading',
    // 'imageInsert',
    '|',
    'fontFamily',
    'fontSize',
    'fontColor',
    'fontBackgroundColor',
    '|',
    'bold',
    'italic',
    'underline',
    '|',
    'alignment',
    'outdent',
    'indent',
    'numberedList',
    'bulletedList',
    '|',
    'link',
  ];

  const onDocumentChange = useDebounce(() => {
    const editor = getCKEditorInstance(`formComponentEditor-${testId}`);
    if (editor) {
      const currentText = editor.getData();
      onChange(currentText);
    }
  }, DEBOUNCE.MEDIUM);

  return (
    <FormFieldWrapper
      {...getFormFieldWrapperProps(props, ['id'])}
      testId={`${testId}-wrap`}
      labelIcon={labelIcon}
      labelIconAction={labelIconAction}
      labelIconTooltip={labelIconTooltip}
      labelClassNames={labelClassNames}
      extraClassNames={`ckEditorFormComponent${showError ? ' error' : ''}`}
      showError={showError}
      customErrorText={customErrorText}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          return (
            <div className="flexFill height-maximum flexRowContainer">
              <CKEditorAsync
                id={`CKEditor${testId}`}
                placeholder={placeholder}
                document={value}
                toolbar={toolbar}
                plugins={[]}
                canEdit={true}
                afterOnInit={_.noop}
                onDestroy={_.noop}
                onDocumentChange={onDocumentChange}
                domId={`formComponentEditor-${testId}`}
                shouldFocusOnInit={false}
              />
            </div>
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};
