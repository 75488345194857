import { flux } from '@/core/flux.module';
import { Search } from '@/itemFinder/itemFinder.constants';

export function setName(name: string) {
  flux.dispatch('ITEM_FINDER_SET_NAME', { name });
}

export function setCronSchedule(cronSchedule: string[]) {
  flux.dispatch('ITEM_FINDER_SET_CRON_SCHEDULE', { cronSchedule });
}

export function addSearch() {
  flux.dispatch('ITEM_FINDER_ADD_SEARCH');
}

export function removeSearch(index: number) {
  flux.dispatch('ITEM_FINDER_REMOVE_SEARCH', index);
}

export function updateSearch(payload: { value: Partial<Search>; index: number }) {
  flux.dispatch('ITEM_FINDER_UPDATE_SEARCH', payload);
}

export function setPropertiesAndValues(searches: any) {
  flux.dispatch('ITEM_FINDER_SET_PROPERTIES_AND_VALUES', { searches });
}

export function addProperty(searchId: string | number) {
  flux.dispatch('ITEM_FINDER_ADD_PROPERTY', searchId);
}

export function removeProperty(propertyIndex: number) {
  flux.dispatch('ITEM_FINDER_REMOVE_PROPERTY', propertyIndex);
}

export function updateProperty(payload: { value: any; index: number }) {
  flux.dispatch('ITEM_FINDER_UPDATE_PROPERTY', payload);
}

export function addValue(payload: { propertyId: string; searchId: string | number }) {
  flux.dispatch('ITEM_FINDER_ADD_VALUE', payload);
}

export function removeValue(valueIndex: number) {
  flux.dispatch('ITEM_FINDER_REMOVE_VALUE', valueIndex);
}

export function updateValue(payload: { value: any; index: number }) {
  flux.dispatch('ITEM_FINDER_UPDATE_VALUE', payload);
}

export function resetItemFinder() {
  flux.dispatch('RESET_ITEM_FINDER', {});
}

export function setItemFinder({
  value: { name, searches, cronSchedule },
}: {
  value: { name: string; searches: Search[] | undefined; cronSchedule: string[] | undefined };
}) {
  flux.dispatch('SET_EXISTING_ITEM_FINDER', { value: { name, searches, cronSchedule } });
}
