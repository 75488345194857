// @ts-strict-ignore
import _ from 'lodash';
import { PersistenceLevel, Store } from '@/core/flux.service';
import { DATASOURCES_TAB_INDEX } from '@/administration/administration.constants';
import { CapabilityInheritanceOutputV1 } from '@/sdk';
import { CapabilityEnum } from '@/sdk/model/CapabilityInheritanceOutputV1';

/**
 * The administration store handles the known users.
 */
export class AdministrationStore extends Store {
  persistenceLevel: PersistenceLevel = 'NONE';
  static readonly storeName = 'sqAdministrationStore';

  initialize() {
    this.state = this.immutable({
      activeTabIndex: DATASOURCES_TAB_INDEX,
      groups: [],
      capabilitiesInEditor: [],
      groupUnsavedChanges: false,
      editorCapabilities: [],
      adminContactInfo: {
        name: '',
        email: '',
      },
      searchParams: {},
    });
  }

  get activeTabIndex() {
    return this.state.get('activeTabIndex');
  }

  get groups() {
    return this.state.get('groups');
  }

  get capabilitiesInEditor(): CapabilityInheritanceOutputV1[] {
    return this.state.get('capabilitiesInEditor');
  }

  get adminContactInfo() {
    return this.state.get('adminContactInfo');
  }

  get searchParams() {
    return this.state.get('searchParams');
  }

  get tooManyRequests() {
    return this.state.get('tooManyRequests');
  }

  protected readonly handlers = {
    /**
     * Sets the active tab index
     *
     * @param {Object} tab - The new active tab
     */
    ADMINISTRATION_SET_ACTIVE_TAB_INDEX: (tab) => {
      this.state.set('activeTabIndex', tab);
    },

    ADMINISTRATION_SET_USERS: (userList) => {
      this.state.set('users', userList);
    },

    ADMINISTRATION_SET_USER_TABLE_LOADING: ({ loading }) => {
      this.state.set('userTableLoading', loading);
    },

    ADMINISTRATION_SET_SEARCH_PARAMS: ({ field, value }) => {
      const searchParams = this.state.get('searchParams');

      if (value === '') {
        this.state.set('searchParams', _.omit(searchParams, field));
      } else {
        this.state.set('searchParams', _.assign({}, searchParams, { [field]: value }));
      }
      this.state.commit();
    },

    ADMINISTRATION_RESET_SEARCH_PARAMS: () => {
      this.state.set('searchParams', {});
    },

    /**
     * Clears the array of groups
     */
    ADMINISTRATION_CLEAR_GROUPS: () => {
      this.state.set('groups', []);
    },

    ADMINISTRATION_ADD_GROUP: (group) => {
      this.state.push('groups', group);
    },

    /**
     * Updates properties for a group
     *
     * @param {Object} group - The group to be updated
     */
    ADMINISTRATION_UPDATE_GROUP: (group) => {
      const index = _.findIndex(this.state.get('groups'), ['id', group.id]);
      if (index > -1) {
        this.state.merge(['groups', index], group);
      }
    },

    /**
     * Removes groups from the array of groups
     *
     * @param ids - The ids of the groups to be removed
     */
    ADMINISTRATION_REMOVE_GROUPS: (ids: string[]) => {
      _.forEach(ids, (id) => {
        const index = _.findIndex(this.state.get('groups'), ['id', id]);

        if (index >= 0) {
          this.state.splice('groups', [index, 1]);
        }
      });
    },

    ADMINISTRATION_CAPABILITIES_IN_EDITOR: (capabilitiesInEditor: CapabilityInheritanceOutputV1[]) => {
      this.state.set('capabilitiesInEditor', capabilitiesInEditor);
    },

    ADMINISTRATION_CAPABILITIES_TOGGLE_SELECTION: (capability: CapabilityEnum) => {
      const newSelections = _.some(this.capabilitiesInEditor, { capability })
        ? _.reject(this.capabilitiesInEditor, { capability })
        : _.concat(this.capabilitiesInEditor, { capability });
      this.state.set('capabilitiesInEditor', newSelections);
    },

    /**
     * Update the administrator's contact information in the store
     *
     * @param  {Object} adminContactInfo the contact information of the customer's Seeq administrator
     * @param  {String} adminContactInfo.name the name of the customer's Seeq administrator
     * @param  {String} adminContactInfo.email the email of the customer's Seeq administrator
     */
    ADMINISTRATION_UPDATE_CONTACT_INFO: (adminContactInfo) => {
      this.state.set('adminContactInfo', adminContactInfo);
    },

    /**
     * Save boolean indicating if we have more than one
     * page of requests
     *
     * @param {Object} tooManyRequests - true if we have
     * more than one page of requests
     */
    ADMINISTRATION_SET_TOO_MANY_REQUESTS: (tooManyRequests) => {
      this.state.set('tooManyRequests', tooManyRequests);
    },
  };
}
