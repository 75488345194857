import _ from 'lodash';
import { MutableRefObject } from 'react';

export function scrollToBottom<T extends HTMLElement>(scrollElementRef: MutableRefObject<T>) {
  scrollElementRef.current.scrollTo({
    behavior: 'smooth',
    top: scrollElementRef.current.scrollHeight,
  });
}

export function scrollTo<T extends HTMLElement | undefined>(
  scrollElementRef: MutableRefObject<T>,
  top: number,
  containerPath = '',
) {
  const container = _.get(scrollElementRef.current, containerPath, scrollElementRef.current);
  (container as HTMLElement).scrollTo({
    behavior: 'smooth',
    top,
  });
}

export function getFavIconElement(): HTMLAnchorElement {
  return document.getElementById('favicon') as HTMLAnchorElement;
}

/**
 * Focuses and selects the text content of an HTML input element when triggered by a mouse event.
 *
 * @param event - The focus event that triggers the function, typically from a React component.
 */
export function focusAndSelectElement(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
  event.currentTarget.focus();
  event.currentTarget.select();
}
