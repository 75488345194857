import { PersistenceLevel, Store } from '@/core/flux.service';
import _ from 'lodash';
import { ViewDateRange } from '@/vantage/types/DateTime.types';
import { SimplifiedNode } from '@/vantage/types/SimplifedNode.types';

export class VantageTableStore extends Store {
  persistenceLevel: PersistenceLevel = 'WORKSHEET';
  static readonly storeName = 'sqVantageTableStore';

  initialize(): void {
    this.state = this.immutable({
      selection: [],
      gridState: {},
      quickFilter: '',
      dateRange: {
        lookBack: { num: 7, unit: { value: 'day', label: 'day' } },
        lookForward: { num: 0, unit: { value: 'day', label: 'day' } },
        range: [new Date(new Date().valueOf() - 7 * 24 * 60 * 60 * 1000).toISOString(), new Date().toISOString()],
      },
    });
  }

  get selection(): SimplifiedNode[] {
    return this.state.get('selection');
  }

  get gridState(): any {
    return this.state.get('gridState');
  }

  get quickFilter(): string {
    return this.state.get('quickFilter');
  }

  get dateRange(): ViewDateRange {
    return this.state.get('dateRange');
  }

  dehydrate() {
    return _.omit(this.state.serialize(), 'selection');
  }

  rehydrate(dehydratedState: any) {
    this.state.deepMerge(dehydratedState);
  }

  protected readonly handlers = {
    SET_VANTAGE_SELECTION: (payload: { selection: SimplifiedNode[] }) => this.state.set('selection', payload.selection),
    SET_VANTAGE_GRID_STATE: (payload: { gridState: any }) => this.state.set('gridState', payload.gridState),
    SET_VANTAGE_QUICK_FILTER: (payload: { quickFilter: string }) => this.state.set('quickFilter', payload.quickFilter),
    SET_VANTAGE_DATE_RANGE: (payload: { dateRange: ViewDateRange }) => this.state.set('dateRange', payload.dateRange),
  };
}
