import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@seeqdev/qomponents';
import { ButtonVariant } from '@seeqdev/qomponents/dist/Button/Button.types';
import { useTranslation } from 'react-i18next';
import { ContinueButtonProps } from '@/formbuilder/formBuilder.constants';

type CancelSaveAndContinueProps = ContinueButtonProps & {
  submitFn?: (...values: any[]) => any;
  cancelFn?: () => void;
  values: any[];
  btnDisabled?: boolean;
  cancelBtnLabel?: string;
  submitBtnLabel?: string;
  cancelClassNames?: string;
  submitClassNames?: string;
  cancelBtnTestId?: string;
  submitBtnTestId?: string;
  submitBtnVariant?: ButtonVariant;
  hideSubmit?: boolean;
  hideCancel?: boolean;
  // this is useful for those rare cases where the submit button should remain in a loading state after the submitFn is
  // resolved (ex. oauth)
  continueProcessing?: boolean;
  setIsContinueButtonClicked?: (isContinueButtonClicked: boolean) => void;
};

/**
 * Cancel and Save buttons
 */
export const CancelSaveAndContinue: React.FunctionComponent<CancelSaveAndContinueProps> = (props) => {
  const {
    submitFn,
    cancelFn,
    btnDisabled,
    values,
    cancelBtnLabel = 'CANCEL',
    submitBtnLabel,
    cancelClassNames = '',
    submitClassNames = '',
    cancelBtnTestId = 'cancelButton',
    submitBtnTestId = 'executeButton',
    submitBtnVariant = 'theme',
    hideSubmit,
    hideCancel,
    continueProcessing,
    continueButtonLabel,
    continueButtonEnabled,
    continueButtonVariant,
    continueButtonPosition,
    continueButtonExtraClassNames,
    setIsContinueButtonClicked,
  } = props;

  const [processing, setProcessing] = useState(false);
  const [continueButtonProcessing, setIsContinueButtonProcessing] = useState(false);
  const isClosed = useRef(false);
  const { t } = useTranslation();

  useEffect(
    () => () => {
      isClosed.current = true;
    },
    [],
  );

  const resetButtons = () => {
    setIsContinueButtonProcessing(false);
    setProcessing(false);
  };

  const manageSubmission = async (isExtraButton = false) => {
    isExtraButton ? setIsContinueButtonProcessing(true) : setProcessing(true);
    setIsContinueButtonClicked && setIsContinueButtonClicked(isExtraButton);

    return Promise.resolve()
      .then(() => submitFn?.(values))
      .finally(() => !isClosed.current && resetButtons());
  };

  const continueSubmitButton = (
    <Button
      id="extraSave"
      type="submit"
      onClick={() => manageSubmission(true)}
      label={continueButtonLabel && t(continueButtonLabel)}
      disabled={!continueButtonEnabled || continueButtonProcessing || processing || continueProcessing || btnDisabled}
      size="sm"
      icon={continueButtonProcessing || continueProcessing ? 'fa-spinner fa-spin-pulse fa-lg sq-icon-white mr5' : ''}
      iconPrefix="fa-solid"
      testId="extraButtonTestId"
      extraClassNames={continueButtonExtraClassNames || 'min-width-100'}
      variant={continueButtonVariant}
    />
  );

  return (
    <>
      {!hideCancel && (
        <Button
          onClick={() => cancelFn?.()}
          label={t(cancelBtnLabel)}
          size="sm"
          testId={cancelBtnTestId}
          extraClassNames={cancelClassNames || 'mr20 min-width-100 width-100 max-width-100'}
        />
      )}
      {continueButtonLabel && continueButtonPosition === 'left' && continueSubmitButton}
      {!hideSubmit && (
        <Button
          id="save"
          type="submit"
          onClick={() => manageSubmission(false)}
          label={submitBtnLabel ? t(submitBtnLabel) : t('SAVE')}
          disabled={btnDisabled || processing || continueProcessing}
          size="sm"
          icon={processing || continueProcessing ? 'fa-spinner fa-spin-pulse fa-lg sq-icon-white mr5' : ''}
          iconPrefix="fa-solid"
          testId={submitBtnTestId}
          extraClassNames={submitClassNames || 'min-width-100'}
          variant={submitBtnVariant}
        />
      )}
      {continueButtonLabel && continueButtonPosition === 'right' && continueSubmitButton}
    </>
  );
};
