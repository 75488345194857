import React from 'react';
import { FORM_ERROR, FORM_WARNING } from '@/formbuilder/formBuilder.constants';
import { useTranslation } from 'react-i18next';
import { Alert } from '@seeqdev/qomponents';

interface ErrorWithBackgroundProps {
  errorText: string;
  errorHeading?: string;
  onClose?: () => void;
  dismissible?: boolean;
  type?: typeof FORM_ERROR | typeof FORM_WARNING;
  errorParameters?: Record<string, string | number>;
}

/**
 * Generic error message display
 */
export const ErrorWithBackground: React.FunctionComponent<ErrorWithBackgroundProps> = (props) => {
  const { errorText, errorHeading, dismissible = false, onClose, type = FORM_ERROR, errorParameters = {} } = props;
  const { t } = useTranslation();
  const [show, setShow] = React.useState(true);

  const handleClose = () => {
    setShow(false);
    onClose && onClose();
  };

  return (
    <Alert
      variant={type === FORM_ERROR ? 'danger' : 'warning'}
      onClose={handleClose}
      dismissible={dismissible}
      extraClassNames="mb0"
      testId="error-with-background"
      show={show}>
      {errorHeading && <div className="fs14 text-bolder mt0">{t(errorHeading)}</div>}
      <p className="max-height-120 overflowAuto text-pre-wrap breakWord">{t(errorText, errorParameters)}</p>
    </Alert>
  );
};
