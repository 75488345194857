import _ from 'lodash';
import { sqUsersApi } from '@/sdk/api/UsersApi';
import { flux } from '@/core/flux.module';
import { PUSH_IGNORE, PUSH_WORKBENCH } from '@/core/flux.service';
import { isViewOnlyWorkbookMode } from '@/utilities/utilities';
import { exposedForTesting } from '@/trendData/trend.actions';
import { sqWorkbenchStore } from '@/core/core.stores';
import { Locale } from 'date-fns';
import { AgGridLocale } from '@/utilities/i18n.constants';

/**
 * Toggles the worksheet panel
 */
export function toggleWorksheetPanel(): void {
  const viewOnly = isViewOnlyWorkbookMode();
  flux.dispatch('WORKSHEET_PANEL_TOGGLE', { viewOnly }, PUSH_WORKBENCH);
}

export async function setTemplateWorkstepId(workstepId: string): Promise<any> {
  let user = sqWorkbenchStore.currentUser;
  return sqUsersApi.updateUser(
    {
      email: user.email,
      username: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      templateWorkstepId: workstepId,
    },
    { id: user.id },
  );
}

/**
 * Sets the flag determining if links to an Analysis or Topic should open in a new tab.
 *
 * @param preferNewTab - True if the user prefers links to open in a new tab
 */
export function setPreferNewTab(preferNewTab: boolean): void {
  flux.dispatch('SET_PREFER_NEW_TAB', { preferNewTab }, PUSH_WORKBENCH);
}

/**
 * Sets a flag that indicates whether to display the "Edit Profile" dialog.
 *
 * @param display - true if "Edit Profile" should be shown, false otherwise.
 */
export function setUserProfileDisplay(display: boolean): void {
  flux.dispatch('SET_DISPLAY_USER_PROFILE', { displayUserProfile: display }, PUSH_WORKBENCH);
}

/**
 * Sets a flag that indicates whether to display the "Edit Preferences" dialog.
 *
 * @param display - true if "Edit Preferences" should be shown, false otherwise.
 */
export function setUserPreferencesDisplay(display: boolean): void {
  flux.dispatch('SET_DISPLAY_USER_PREFERENCES', { displayUserPreferences: display }, PUSH_WORKBENCH);
}

/**
 * Sets a flag that specifies tab for user preferences, system vs. worksheet preferences
 *
 * @param tab
 */
export function setUserPreferencesTab(tab: string): void {
  flux.dispatch('SET_USER_PREFERENCES_TAB', { userPreferencesTab: tab }, PUSH_WORKBENCH);
}

/**
 * Passes in a description of the params of the state being transitioned to
 *
 * @param stateParams - the state params from app.module
 */
export function setStateParams(stateParams: any): void {
  flux.dispatch('SET_STATE_PARAMS', { stateParams }, PUSH_IGNORE);
}

/**
 * If a user is logged in, it sets the properties of the currently logged in user. It uses the authentication token
 * to retrieve the user id as this is what allows the app to bootstrap when being loaded initially. After this the
 * user id will be available on sqWorkbenchStore.currentUser.
 *
 * @returns A promise that resolves when the current user's properties have been updated.
 */
export function setCurrentUser(): Promise<any> {
  return sqUsersApi.getMe({ includeGroups: false }).then(({ data: user }) =>
    flux.dispatch(
      'SET_CURRENT_USER',
      _.assign(
        {
          isAdmin: user.isAdmin,
          isPasswordSettable: user.isPasswordSettable,
          passwordExpiryStatus: user.passwordExpiryStatus,
          lastPasswordChangedAt: user.lastPasswordChangedAt,
          requirePasswordResetOnNextLogin: user.requirePasswordResetOnNextLogin,
        },
        _.pick(user, [
          'id',
          'firstName',
          'lastName',
          'name',
          'email',
          'username',
          'workbench',
          'templateWorkstepId',
          'capabilityGrants',
          'role',
          'manager',
          'site',
        ]),
      ),
      PUSH_IGNORE,
    ),
  );
}

/**
 * Sets the user's time zone
 *
 * @param timeZone - Time zone to set for the current user
 * @param fetchCapsules - true if changing tz while capsule table is visible
 * @param timeZone.name - Name of the time zone to set
 */
export function setUserTimeZone(timeZone: object): void {
  flux.dispatch('SET_USER_TIME_ZONE', timeZone);
  exposedForTesting.fetchTableAndChartCapsules();
}

/**
 * Sets the locale data for the date-fns library for the current session based on
 * the browser locale code. The library uses this data to format dates in a locale-specific manner.
 *
 * @param locale - Date FNS Locale object
 */
export function setDateFnsLocale(locale: Locale): void {
  flux.dispatch('SET_DATE_FNS_LOCALE', { locale });
}

/**
 * Sets the locale data for the ag-grid library.
 *
 * @param locale - Ag-Grid Locale data
 */
export function setAgGridLocale(locale: AgGridLocale): void {
  flux.dispatch('SET_AG_GRID_LOCALE', { locale });
}

/**
 * Sets the check for if the drag and drop popup should be shown
 *
 * @param showDragAndDropPopup - Determines if the popup should be shown
 */
export function setShowDragAndDropPopup(showDragAndDropPopup: boolean): void {
  flux.dispatch('SET_SHOW_DRAG_AND_DROP_POPUP', showDragAndDropPopup, PUSH_WORKBENCH);
}

/**
 * Sets the journal that is being opened
 *
 * @param journal - Object of the journal being opened
 */
export function setJournalToOpen(journal: any | null): void {
  flux.dispatch('SET_JOURNAL_TO_OPEN', journal, PUSH_WORKBENCH);
}

/**
 * Creates a new interactive session ID
 */
export function generateNewSessionId(): void {
  flux.dispatch('SET_NEW_SESSION');
}

/**
 * Sets the PI Vision home URL so the user doesn't have to keep re-entering it each time they do an export.
 *
 * @param piVisionHomeURL - The home URL of a PI Vision server.
 */
export function setPIVisionHomeURL(piVisionHomeURL: string): void {
  flux.dispatch('SET_PI_VISION_HOME_URL', { piVisionHomeURL }, PUSH_WORKBENCH);
}

/**
 * Dispatches an event to add the provided color to the mostRecentColors array.
 *
 *  @param color - a String representing a color in Hex format (e.g. #cccccc)
 */
export function addRecentColor(color: string): void {
  flux.dispatch('ADD_RECENT_COLOR', { color }, PUSH_WORKBENCH);
}

/**
 * Stores the date (as an ISO string) until which the license expiration message has been snoozed.
 *
 * @param snoozeUntil - the date until which the license expiration message has been snoozed
 */
export function setLicenseExpirationSnooze(snoozeUntil: string): void {
  flux.dispatch('SET_LICENSE_EXPIRATION_SNOOZE', { snoozeUntil }, PUSH_WORKBENCH);
}

/**
 * Sets the loadingItemId that determines if the loading indicator spinners should be shown for a workbook or folder.
 */
export function setLoadingItemId(id: string): void {
  flux.dispatch('WORKBOOK_SET_LOADING_ID', { id }, PUSH_WORKBENCH);
}

/**
 * Sets the opening ItemId that determines if the opening indicator spinners should be shown for a workbook or folder.
 */
export function setOpeningAndLoadingItemId(id: string | null): void {
  flux.dispatch('WORKBOOK_SET_OPENING_LOADING_ID', { id }, PUSH_WORKBENCH);
}

/**
 * Stores a hash of the system message that has been dismissed
 *
 * @param messageHash
 */
export function setSystemMessageHash(message: string): void {
  flux.dispatch('SET_SYSTEM_MESSAGE_HASH', { message }, PUSH_WORKBENCH);
}

export function setTourShown(tourShown: string): void {
  flux.dispatch('SET_TOUR_SHOWN', { tourShown }, PUSH_WORKBENCH);
}

export function setDarkMode(darkMode: boolean): void {
  flux.dispatch('SET_DARK_MODE', { darkMode }, PUSH_WORKBENCH);
}

export function setTriggerConfetti(triggerConfetti: boolean, confettiMessageKey: string): void {
  flux.dispatch('SET_TRIGGER_CONFETTI', { triggerConfetti, confettiMessageKey }, PUSH_WORKBENCH);
}

export function setHasCreatedCondition(hasCreatedCondition: boolean): void {
  flux.dispatch('SET_HAS_CREATED_CONDITION', { hasCreatedCondition }, PUSH_WORKBENCH);
}

export function setUserDatasources(userDatasources: string[], includeNewDatasources: boolean): void {
  flux.dispatch('SET_USER_DATASOURCES', { userDatasources, includeNewDatasources }, PUSH_WORKBENCH);
}

export function setIsUsingDatasourcePrefsAssetTrees(value: boolean): void {
  flux.dispatch('SET_IS_USING_DATASOURCE_PREFS_ASSET_TREES', { value }, PUSH_WORKBENCH);
}

export function toggleFormulaHelp(): void {
  flux.dispatch('TOGGLE_FORMULA_HELP_SHOWN');
}

export function setFormulaHelpView(view: string): void {
  flux.dispatch('SET_FORMULA_HELP_VIEW', { view });
}
